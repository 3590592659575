import { defineStore } from 'pinia';
import { get } from 'lodash-es';
import { bus } from '@/lib/eventBus';
import {
  useMessageStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

const useChannelStore = defineStore('studio-channel', {
  state: () => ({
    channelIds: [],
  }),
  actions: {
    [types.SET_CHANNELS](channelIds) {
      this.channelIds = channelIds;
    },
    async [types.GET_CHANNELS](payload) {
      const { allowPublicAccess } = payload;

      try {
        const channelsRes = await this.deApi({
          method: 'GET',
          url: '/home/channels',
          skipErrorHandling: allowPublicAccess || false,
        });
        this.channelIds = channelsRes.data.channels;
      } catch (e) {
        if (allowPublicAccess) {
          // For publicly-accessible boards, we'd expect this to error
          return;
        }
        throw e;
      }
    },
    async [types.SUBSCRIBE_TO_CHANNEL](asset) {
      const messageStore = useMessageStore();
      try {
        const res = await this.deApi.put(`/home/channel/${asset.id}`);

        if (res?.status === 200) {
          messageStore[types.SET_APP_MESSAGE]({
            type: 'ChannelSubscribeMessage',
            op: 'add',
            assetName: asset.name || asset.title,
          });
          this.channelIds.push(asset.id);
          bus.emit('channel:subscribe', { asset });
        } else {
          throw res;
        }
      } catch (error) {
        messageStore[types.SET_APP_MESSAGE]({
          type: 'ChannelSubscribeErrorMessage',
          op: 'add',
          message: get(error, 'response.data.meta.message', error),
        });
      }
    },
    async [types.UNSUBSCRIBE_FROM_CHANNEL](asset) {
      const messageStore = useMessageStore();
      try {
        const res = await this.deApi.delete(`/home/channel/${asset.id}`);

        if (res?.status === 200) {
          messageStore[types.SET_APP_MESSAGE]({
            type: 'ChannelSubscribeMessage',
            op: 'remove',
            assetName: asset.name || asset.title,
          });
          bus.emit('channel:unsubscribe', { asset });
          const idx = this.channelIds.indexOf(asset.id);
          if (idx === -1) return;
          this.channelIds.splice(idx, 1);
        } else {
          throw res;
        }
      } catch (error) {
        messageStore[types.SET_APP_MESSAGE]({
          type: 'ChannelSubscribeErrorMessage',
          op: 'remove',
          message: get(error, 'response.data.meta.message', error),
        });
      }
    },
  },
});

export default useChannelStore;
