import i18next from '@/lib/i18next';

export const DOMAIN_NAME_WITHOUT_SUFFIX = 'discoveryeducation';

export const INTERNAL_DOMAINS = [
  'dxm24f3wn5min.cloudfront.net',
  'd1m0scxbrw6oep.cloudfront.net',
  'd2e2ijvon1zvjh.cloudfront.net',
  'd3pzvoc9ouygdh.cloudfront.net',
  'd1f70csyu11if4.cloudfront.net',
  'd52lz4eynfxmq.cloudfront.net',
  'dndakvuq1qjuy.cloudfront.net',
  'dumm9n5bt2uls.cloudfront.net',
];

// Student view
export const STUDENT_VIEW_ACTION_BAR_HEIGHT = 56;

// TEI
export const TEI_CHECKING_ANSWERS_MODAL_DELAY = 1000;
export const TEI_INSTRUCTIONS_CARD_ALLOWED_ELEMENTS = ['br', 'div', 'span', 'ul', 'li', 'ol'];
export const TRY_TYPE_SUBMIT = 2;
export const TRY_TYPE_SCORE_OVERWRITTEN = 9;

// Slides have an original set size, approximately 16:9
export const EDITOR_CANVAS_WIDTH = 1000;
export const EDITOR_CANVAS_HEIGHT = 544;
export const EDITOR_CANVAS_PADDING = 56;
export const EDITOR_CANVAS_PADDING_HORIZONTAL_CIRCLE_BUTTON = 96;
export const EDITOR_CANVAS_PADDING_HORIZONTAL_CIRCLE_BUTTON_SMALL = 66;
export const EDITOR_CANVAS_PADDING_VERTICAL_WITH_HEADER = 38;
export const EDITOR_CANVAS_PADDING_MOBILE_VERTICAL = 1;
export const EDITOR_CANVAS_PADDING_MOBILE_VERTICAL_WITH_HEADER = 8;
export const EDITOR_CANVAS_MIN_LAYOUT_HEIGHT = 352;
export const EDITOR_CANVAS_GRID_MARGIN = 16;
export const EDITOR_CANVAS_GRID_ROW_HEIGHT = 32;
export const EDITOR_CANVAS_GRID_COL_NUM = 12;
export const EDITOR_CANVAS_NO_GRID_ROW_HEIGHT = 1;
export const EDITOR_CANVAS_NO_GRID_MARGIN = 0;
export const EDITOR_CANVAS_SLIDES_MAX_ROWS = 11;
export const EDITOR_ZOOM_CONTENT_HEADER_HEIGHT = 24;

export const EDITOR_SNAP_GRID_CELL_WIDTH = 20;
export const EDITOR_SNAP_GRID_CELL_HEIGHT = 17;
export const EDITOR_SNAP_GRID_CELL_LARGE_WIDTH = 10;
export const EDITOR_SNAP_GRID_CELL_LARGE_HEIGHT = 8;

export const EDITOR_SLIDE_BUTTON_RECTANGLE_HEIGHT = 110;
export const EDITOR_SLIDE_BUTTON_CIRCLE_HEIGHT = 48;
export const EDITOR_SLIDE_BUTTON_CIRCLE_HEIGHT_SMALL = 35;

export const MODAL_CANVAS_WIDTH = 480;
export const MODAL_CANVAS_HEIGHT = 544;
export const MODAL_CANVAS_PADDING = 48;
export const MODAL_CANVAS_PLACE_MODULE_BLEED = 60;
export const MODAL_SNAP_GRID_CELL_WIDTH = 20;
export const MODAL_SNAP_GRID_CELL_HEIGHT = 17;
export const MODAL_SNAP_GRID_CELL_LARGE_WIDTH = 8;
export const MODAL_SNAP_GRID_CELL_LARGE_HEIGHT = 8;

export const EDITOR_MODULE_IGNORE_DRAG_SELECTOR = `a:not(.button-module), button:not(.button-module), [contenteditable="true"], input,
  textarea, select, .video-player, video, .audio-viewer__wave, .multiple-choice-editor__option-handle,
  .image-viewer__background--draggable`;

export const PRODUCT_SETTING_ALLOW_STUDIO = 'ALLOWSTUDIO';
export const PRODUCT_SETTING_DISTRIBUTABLE_LINKS_ENABLED = 'DISTRIBUTABLE_LINKS_ENABLED';
export const PRODUCT_SETTING_HAS_SUPPORT_CHAT = 'HASSUPPORTCHAT';
export const PRODUCT_SETTING_HIDE_PROFILE = 'HIDEPROFILE';
export const PRODUCT_SETTING_INACTIVITY_TIMEOUT = 'INACTIVITYTIMEOUT';
export const PRODUCT_SETTING_STUDIO_CHAT_ENABLED = 'STUDIOCHATENABLED';
export const PRODUCT_SETTING_STUDIO_TEMPLATES_ENABLED = 'STUDIOTEMPLATESENABLED';
export const PRODUCT_SETTING_STUDIO_LESSONS_ENABLED = 'ENABLESTUDIOLESSONS';
export const PRODUCT_SETTING_ENABLE_STUDENT_PROFILE = 'ENABLESTUDENTPROFILE';
export const PRODUCT_SETTING_SHOW_SEARCH = 'SHOWSEARCH';
export const PRODUCT_SETTING_STUDENT_SEARCH = 'STUDENTSERACH'; // yes this is really how it's spelled
export const PRODUCT_SETTING_MAZE_TRACKING_ENABLED = 'MAZE_TRACKING_ENABLED';

// This is the list of all product settings used in Studio. If you need to use another one,
// add it here and make sure it has EXT_USER_ACCESSIBLE = 1 in the database.
export const PRODUCT_SETTING_CODES = [
  PRODUCT_SETTING_ALLOW_STUDIO,
  PRODUCT_SETTING_DISTRIBUTABLE_LINKS_ENABLED,
  PRODUCT_SETTING_HAS_SUPPORT_CHAT,
  PRODUCT_SETTING_HIDE_PROFILE,
  PRODUCT_SETTING_INACTIVITY_TIMEOUT,
  PRODUCT_SETTING_STUDIO_CHAT_ENABLED,
  PRODUCT_SETTING_STUDIO_TEMPLATES_ENABLED,
  PRODUCT_SETTING_STUDIO_LESSONS_ENABLED,
  PRODUCT_SETTING_ENABLE_STUDENT_PROFILE,
  PRODUCT_SETTING_SHOW_SEARCH,
  PRODUCT_SETTING_STUDENT_SEARCH,
  PRODUCT_SETTING_MAZE_TRACKING_ENABLED,
];

export const ROLE_GLOBAL_ADMIN = 'GLOBALADMIN';
export const ROLE_EDITOR = 'EDITOR';
export const ROLE_STUDIO_VIEWER = 'STUDIOVIEWER';
export const ROLE_STUDIO_EDITOR = 'STUDIOEDITOR';
export const ROLE_STUDIO_ADMIN = 'STUDIOADMIN';
export const ROLE_EDITORIAL_ADMIN = 'EDADMIN';
export const ROLE_EDITORIAL_EDITOR = 'EDEDITOR';
export const ROLE_EDITORIAL_VIEWER = 'EDVIEWER';
export const ROLE_STUDENT = 'STUDENT';
export const ROLE_TEACHER = 'TEACHER';
export const ROLE_ACCOUNT_ADMIN = 'ACCTADMIN';
export const ROLE_MULTI_ACCOUNT_ADMIN = 'MULTIACCTADMIN';
export const ROLE_SITE_ADMIN = 'SITEADMIN';
export const ROLES_WITH_STUDIO_ACCESS = [
  ROLE_GLOBAL_ADMIN,
  ROLE_STUDIO_VIEWER,
  ROLE_STUDIO_EDITOR,
  ROLE_STUDIO_ADMIN,
];

export const EDITORIAL_ROLES = [
  ROLE_STUDIO_VIEWER,
  ROLE_STUDIO_EDITOR,
  ROLE_STUDIO_ADMIN,
  ROLE_EDITORIAL_ADMIN,
  ROLE_EDITORIAL_EDITOR,
  ROLE_EDITORIAL_VIEWER,
];

// These role labels are used in the user profile dropdown
export const editorialRoleLabels = () => ({
  [ROLE_GLOBAL_ADMIN]: i18next.t('Global Admin'),
  [ROLE_STUDIO_VIEWER]: i18next.t('Studio Viewer'),
  [ROLE_STUDIO_EDITOR]: i18next.t('Studio Editor'),
  [ROLE_STUDIO_ADMIN]: i18next.t('Studio Admin'),
  [ROLE_EDITORIAL_ADMIN]: i18next.t('Editorial Admin'),
  [ROLE_EDITORIAL_EDITOR]: i18next.t('Editorial Editor'),
  [ROLE_EDITORIAL_VIEWER]: i18next.t('Editorial Viewer'),
});

export const sharingTypes = () => ({
  // Note: keys are string values from edde
  admin: {
    label: i18next.t('Admin'),
    description: i18next.t('Can edit, copy, and share'),
  },
  edit: {
    label: i18next.t('Edit'),
    description: i18next.t('Can edit and copy'),
  },
  view: {
    label: i18next.t('View'),
    description: i18next.t('Can view and copy'),
  },
  readonly: {
    label: i18next.t('Read only'),
    description: i18next.t('Can only view'),
  },
});

export const moduleTypeLabels = () => ({
  asset: i18next.t('Asset'),
  button: i18next.t('Button'),
  multiple_selection: i18next.t('Multiple Select'),
  placeholder: i18next.t('Placeholder'),
  prompt: i18next.t('Short Answer'),
  single_selection: i18next.t('Multiple Choice'),
  text: i18next.t('Text'),
  upload: i18next.t('Upload'),
  block: i18next.t('Block'),
});

export const getTemplatePlaceholders = () => ({
  body_text: i18next.t('Insert text here'),
  headline_text: i18next.t('Insert Headline Here'),
  subheading_text: i18next.t('Insert Subheading Here'),
});

export const TEI_ASSET_SUBTYPES = [
  'selected-response',
  'connections',
  'tiling',
  'select-object',
  'constructed-response',
];

export const interactiveModuleTypes = [
  'multiple_selection',
  'prompt',
  'single_selection',
  'block',
];

// These terms are returned from the API in English. They're only used to check completion
// and are not shown to the user, so they don't need to be translated.
export const COMPLETED_ASSIGNMENT_STATUSES = [
  'Complete',
  'Graded',
  'Pending',
  'Submitted',
];

export const NOT_STARTED_ASSIGNMENT_STATUS = 'Not Started';
export const IN_PROGRESS_ASSIGNMENT_STATUS_ID = 5;

export const fileTypesByGroup = {
  image: [
    'image/gif',
    'image/jpeg',
    'image/png',
  ],
  video: [
    'video/3gpp2',
    'video/3gpp',
    'video/x-ms-asf',
    'video/x-msvideo',
    'video/divx',
    'video/x-f4v',
    'video/x-flv',
    'video/x-m4v',
    'video/x-matroska',
    'video/quicktime',
    'video/mp4',
    'video/mpeg',
    'video/mts',
    'video/x-ms-wmv',
    'application/x-mpegURL',
  ],
  audio: [
    'audio/x-aac',
    'audio/ac3',
    'audio/amr',
    'audio/eac3',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/x-wav',
    'audio/x-ms-wma',
  ],
};

/**
 * Get asset group based on the file type
 * @param {string} fileType - File type to test
 * @returns {string} Asset group, or undefined if none is found
 */
export function getGroupFromFileType(fileType) {
  const groups = Object.keys(fileTypesByGroup);
  return groups.find((group) => fileTypesByGroup[group].includes(fileType));
}

/** Colors available for connected collaborative users */
export const USER_COLORS = [
  '#158368',
  '#c73838',
  '#823f3f',
  '#b15b0d',
  '#078910',
  '#8c7240',
  '#8e4f6a',
  '#5b777d',
  '#6e7fab',
  '#0b78d4',
  '#41528f',
  '#7c54f0',
  '#367243',
  '#a00cd4',
  '#e21d70',
];

export const ASSET_TYPE_IMAGE = '3123b3ad-3c2d-49cf-92ce-9927c3c09e64';
export const ASSET_TYPE_AUDIO = '9c86af9f-5420-4662-abed-910857f7e301';
export const ASSET_TYPE_OTHER = 'e29b6be1-4180-457a-ae83-a66398d71d70';
export const ASSET_TYPE_TITLE_ID = 'b373b9c1-fe6d-4891-9ff3-3b59b23550e2';
export const ASSET_TYPE_NON_SEARCHABLE_IMAGE = '1aa883f4-b310-4a5a-9d35-d07dc8cadf6c';
export const ASSET_TYPE_INVESTIGATION = '9c864e63-0018-452f-8cf6-e7bb8c204646';
export const ASSET_TYPE_TECHNOLOGY_ENHANCED_ASSESSMENT = 'a6d4de3c-8100-4fb5-b910-5cf311b51f1e';
export const ASSET_TYPE_TEI = '82fc565a-b7f5-4686-8ecc-b6c4b44b9e43';

export const ASSET_SUBTYPE_STUDIO_QUIZ = 'f7217318-a375-42be-bee7-1a991e045ef6';
export const ASSET_SUBTYPE_LESSON = '9695ae47-bd47-4070-aa05-b8bdaa6ebcb0';
export const ASSET_TYPE_BOARD = '854f468c-64b9-4bfb-9be2-32c9d5cc5243';
export const ASSET_TYPE_BOARD_PAGE = 'cbe85f11-31bf-40d2-93c2-0164abab65b3';
export const ASSET_TYPE_SLIDESHOW = '9d23b45d-c8da-4fac-852a-c24d5cc4e348';
export const ASSET_TYPE_SLIDE = '1319fa61-9e3f-4669-89ca-e7d6dff47a9d';
export const ASSET_TYPE_LESSON = 'b424e481-30a1-482d-a2d7-ce9c3e5a50d7';

export const ASSET_TYPES = {
  ASSET_TYPE_BOARD,
  ASSET_TYPE_BOARD_PAGE,
  ASSET_TYPE_SLIDESHOW,
  ASSET_TYPE_SLIDE,
  ASSET_TYPE_LESSON,
  ASSET_TYPE_TEI,
  ASSET_TYPE_TECHNOLOGY_ENHANCED_ASSESSMENT,
};

export const ASSET_SUBTYPES = {
  ASSET_SUBTYPE_STUDIO_QUIZ,
  ASSET_SUBTYPE_LESSON,
};

// From https://source.discoveryeducation.com/projects/HUB/repos/edde/browse/edde/constants/asset_type.py
export const USER_UPLOADABLE_ASSET_TYPES = [
  ASSET_TYPE_TITLE_ID, // TITLE_ID (video)
  ASSET_TYPE_IMAGE, // IMAGE
  ASSET_TYPE_AUDIO, // AUDIO
  ASSET_TYPE_OTHER, // OTHER (text/pdf/misc files)
];

export const ASSET_STATUS_ACTIVE = 1;
export const ASSET_STATUS_IN_PROGRESS = 2;
export const ASSET_STATUS_PRIMARY_REVIEW = 3;
export const ASSET_STATUS_SECONDARY_REVIEW = 4;
export const ASSET_STATUS_DELETED = 5;
export const ASSET_STATUS_INCOMPLETE = 6;
export const ASSET_STATUS_PENDING = 7;
export const ASSET_STATUS_APPROVED = 8;
export const ASSET_STATUS_REJECTED = 9;
export const ASSET_STATUS_INACTIVE = 10;
export const ASSET_STATUS_FUTURE_IN_PROGRESS = 11;
export const ASSET_STATUS_FUTURE_READY = 12;
export const ASSET_STATUS_SANITIZING = 13;
export const ASSET_STATUS_ERROR = 14;
export const PROCESSING_ASSET_STATUSES = [
  ASSET_STATUS_IN_PROGRESS,
  ASSET_STATUS_PENDING,
  ASSET_STATUS_SANITIZING,
];
export const NON_PROCESSING_ASSET_STATUSES = [
  ASSET_STATUS_ACTIVE,
  ASSET_STATUS_PRIMARY_REVIEW,
  ASSET_STATUS_SECONDARY_REVIEW,
  ASSET_STATUS_DELETED,
  ASSET_STATUS_INCOMPLETE,
  ASSET_STATUS_APPROVED,
  ASSET_STATUS_REJECTED,
  ASSET_STATUS_INACTIVE,
  ASSET_STATUS_FUTURE_IN_PROGRESS,
  ASSET_STATUS_FUTURE_READY,
  ASSET_STATUS_ERROR,
];

export const assetStatusDescriptions = () => ({
  [ASSET_STATUS_ACTIVE]: i18next.t('Active'),
  [ASSET_STATUS_IN_PROGRESS]: i18next.t('Initialize-In Progress'),
  [ASSET_STATUS_PRIMARY_REVIEW]: i18next.t('Ready for Primary Review'),
  [ASSET_STATUS_SECONDARY_REVIEW]: i18next.t('Ready for Secondary Review'),
  [ASSET_STATUS_DELETED]: i18next.t('Deleted-Inactive'),
  [ASSET_STATUS_INCOMPLETE]: i18next.t('Incomplete'),
  [ASSET_STATUS_PENDING]: i18next.t('Pending'),
  [ASSET_STATUS_APPROVED]: i18next.t('Approved'),
  [ASSET_STATUS_REJECTED]: i18next.t('Rejected'),
  [ASSET_STATUS_INACTIVE]: i18next.t('Inactive'),
  [ASSET_STATUS_FUTURE_IN_PROGRESS]: i18next.t('Future-In Progress'),
  [ASSET_STATUS_FUTURE_READY]: i18next.t('Future-Ready'),
  [ASSET_STATUS_SANITIZING]: i18next.t('Upload-Sanitizing'),
  [ASSET_STATUS_ERROR]: i18next.t('Upload-Error'),
});
