import { nextTick } from 'vue';
import { v4 as uuid, validate as uuidValidate } from 'uuid';
import { defineStore } from 'pinia';
import {
  camelCase, find, get, isError, values, cloneDeep, isNumber, sortBy, findLastIndex,
  first, last, map, merge, omit, isEmpty, keyBy, throttle, isNil, range, truncate,
  partial,
} from 'lodash-es';
import { bus } from '@/lib/eventBus';
import
SharedDocument,
{
  yjsHelper,
  SharedDocumentPollingError,
  SharedDocumentAuthError,
  SharedDocumentCreateSessionError,
  SharedDocumentLoadSessionError,
  UndoManager,
  DocumentChangeStackItem,
  DocumentChange,
  LOCAL_TRANSACTION,
  LOCAL_TRANSACTION_NOT_UNDOABLE,
  LOCAL_TRANSACTION_UNDO_MANAGER,
  LOCAL_TRANSACTIONS,
} from '@/shared-document';
import {
  COMPLETED_ASSIGNMENT_STATUSES,
  NOT_STARTED_ASSIGNMENT_STATUS,
  IN_PROGRESS_ASSIGNMENT_STATUS_ID,
  USER_COLORS,
  EDITOR_CANVAS_WIDTH,
  EDITOR_CANVAS_HEIGHT,
  MODAL_CANVAS_WIDTH,
  MODAL_CANVAS_HEIGHT,
  MODAL_CANVAS_PLACE_MODULE_BLEED,
} from '@/lib/constants';
import i18next from '@/lib/i18next';
import { getLocalData } from '@/lib/localData';
import { decodeHTML, isGuid } from '@/lib/utils';
import {
  createTopZPosition,
  scanGrid,
  bottomYPosition,
  layoutToPixels,
} from '@/lib/utils/grid';
import chain from '@/lib/utils/chain';
import * as utils from '@/lib/utils/store';
import {
  useAppStore,
  useAssessmentStore,
  useAssetStore,
  useChatStore,
  useConfirmationStore,
  useErrorStore,
  useModalStore,
  useToastStore,
  useTrackingStore,
  useUploadStore,
  useUserStore,
} from '@/stores';
import placeModule from '@/lib/utils/placeModule';
import { DraftIsDeletedError } from '@/lib/errors';
import moduleDefaultsList, * as moduleDefaults from '@/lib/constants/moduleDefaults';
import queue from '@/lib/queue';
import * as types from '@/lib/constants/store';

const throttlePatchDraftDescription = throttle((patch) => {
  queue.add(patch);
}, 1000);

const transactionIsLocal = (transaction) => LOCAL_TRANSACTIONS.includes(transaction.origin);

// Holds list of modules added locally.
const localAddedModulesById = {};

// Holds the SharedDocument instance for the draft currently being edited.
let sharedDoc = null;

// Undo manager for the current editing session.
let undoManager;
const applyUndoable = (stackItem) => {
  // Ensure changes are applied to the yDoc.
  if (stackItem.apply) {
    stackItem.apply();
  }
  // If there is an undo manager, add to undo stack.
  if (undoManager) {
    undoManager.add(stackItem);
  }
};

const useEditorStore = defineStore('studio-editor', {
  state: () => ({
    overlayThumbnail: null,
    addModuleDrawerIsOpen: false,
    gridMatrix: null,
    activeCanvasName: null,
    currentModalCanvasId: null,
    currentPageId: null,
    draft: {},
    accessLevelForDraft: null, // don't access this directly; use the getter draftAccessLevel()
    editorDrawers: {
      addModule: false,
      chat: false,
      customization: false,
      notes: false,
      notesFullscreen: true,
      highlightNotes: false,
    },
    editorDrawersAreAnimated: true,
    editorCarouselPageIndex: null,
    editorCarouselIsAnimated: true,
    editorIsFullscreen: false,
    editorViewModePreview: false,
    editorSnapGrid: null,
    editorStatusMessage: null,
    publishedCreationId: null,
    saving: false,
    teiErrors: {},
    thumbnailLoaders: [],
    trackThumbnailLoaders: false,
    topNavDropdownIsOpen: false,
    topNavDrawerIsOpen: false,
    urlRequestCache: {},
    isPreviewMode: false,
    studentView: null,
    canUndo: false,
    canRedo: false,
    moduleClipboard: null,
    videoDurations: {},
    // Fields used for Editor collaborative editing session.
    isConnected: false,
    connectedUsers: [],
    connectedUsersColors: {},
    session: {},
    assignmentId: null,
  }),
  getters: {
    isEditingRestricted() {
      return this.draftIsLesson && !this.draftIsDeCreated;
    },
    pagesSorted: (state) => {
      // no pages? no go
      if (!state.draft || !state.draft.pages) return null;

      const { pages } = state.draft;
      return sortBy(pages, ['sort_index', 'id']);
    },
    pagesById: (state) => keyBy(state.draft?.pages, 'id'),
    sectionsById: (state) => keyBy(state.draft?.options?.groups, 'id'),
    isMysteryTheme() {
      return get(this, 'currentPage.options.theme.name') === 'mystery-science';
    },
    groupedPages(state) {
      // Group pages together by section id. This is used by the PageList
      // and BoardNavigationOverlay, which render pages inside their sections
      const pages = this.pagesSorted || [];

      const sections = keyBy(state?.draft?.options?.groups || [], 'id');

      return pages.reduce((acc, currPage, index) => {
        // It's difficult to know what index a page is if we don't add it here,
        // since the pages won't be in a single-level array anymore
        const formattedCurrPage = {
          ...currPage,
          index,
        };

        const sectionId = formattedCurrPage.options && formattedCurrPage.options.group_id;
        const section = sections[sectionId];

        /*
          If the current page has no section id or if the section
          does not exist, it can be left alone.
        */
        if (!sectionId || !section) {
          acc.push(formattedCurrPage);
          return acc;
        }

        // If the current page is the first one or of a different
        // section than the previous page, it needs to be grouped
        // on its own
        if (!acc.length || last(acc).sectionId !== sectionId) {
          acc.push({
            isSection: true,
            sectionId,
            section,
            pages: [formattedCurrPage],
          });
          return acc;
        }

        // Otherwise the current page can be added to the same group
        // as the previous page
        last(acc).pages.push(formattedCurrPage);
        return acc;
      }, []);
    },
    closestFullscreenVisiblePage() {
      return () => {
        if (!get(this, 'currentPage.options.fullscreen_skip')) {
          return this.currentPage;
        }

        const pages = this.pagesSorted;
        const currentPageIdx = pages.findIndex((page) => (page.id === this.currentPageId));

        const nextPages = pages.slice(currentPageIdx + 1);
        const previousPages = pages.slice(0, currentPageIdx).reverse();
        const closestVisiblePage = chain(previousPages)
          .zip(nextPages)
          .flatten()
          .reject((page) => isNil(page))
          .find((page) => !get(page, 'options.fullscreen_skip'))
          .value();

        return closestVisiblePage;
      };
    },
    currentPageIndex(state) {
      const pages = this.pagesSorted;
      if (!pages) {
        return -1;
      }
      return pages.findIndex((page) => (page.id === state.currentPageId));
    },
    currentPage: (state) => {
      if (!state.draft?.pages) return null;
      return state.draft.pages.find((page) => (page.id === state.currentPageId));
    },
    pageIds: (state) => (state.draft?.pages || []).map((page) => page.id),
    currentPageAssetsLoaded() {
      const assetStore = useAssetStore();
      if (!this.currentPage) return false;
      return this.currentPage.modules
        .filter((mod) => mod.type === 'asset')
        .every((mod) => {
          const assetId = get(mod, 'options.asset_id');
          return !!assetStore.assets[assetId];
        });
    },
    draftAccessLevel: (state) => (state.accessLevelForDraft || state.draft?.access_level),
    draftType: (state) => state.draft?.options?.type,
    draftName(state) {
      const decodedName = decodeHTML(state.draft?.name);
      if (decodedName) return decodedName;

      let defaultName = this.draftType === 'board'
        ? i18next.t('New Board')
        : i18next.t('New Slideshow');

      if (this.draftType === 'lesson') {
        defaultName = i18next.t('New Lesson');
      }

      return defaultName;
    },
    draftAssetId: (state) => (creation) => {
      const draft = creation || state.draft;
      if (!draft || !draft.references) return null;

      // Find the first asset reference that isn't a pointer asset
      const reference = draft.references
        .find((ref) => ref.reference_type === 'asset' && ref.reference_subtype !== 'head');

      return get(reference, 'reference_id');
    },
    draftIsLesson() {
      return this.draftType === 'lesson';
    },
    draftIsSlides() {
      return this.draftType === 'slides';
    },
    draftPointerAssetId: (state) => (draft) => {
      const creation = draft || state.draft;
      if (!creation) return null;
      return chain(creation.references)
        .find((ref) => ref.reference_subtype === 'head')
        .get('reference_id')
        .value();
    },
    draftIsDirty: (state) => get(state, 'session.draftIsDirty', false),
    draftIsDeCreated: (state) => get(state, 'draft.sync_info.is_de_created', false),
    draftCanBeRestored(state) {
      return !this.draftIsDeCreated && get(state, 'draft.is_deleted', false);
    },
    getModuleById: (state) => (id) => utils.getModuleById(state.draft, id),
    interactiveModules: (state) => utils.getInteractiveModules(state.draft),
    draftHasInteractiveModules: (state) => utils.draftHasInteractiveModules(state.draft),
    draftHasTEIErrors: (state) => values(state.teiErrors).some((error) => error),
    gridIsDisabled(state) {
      if (state.currentModalCanvasId) {
        // If a modal canvas is open, the grid is always disabled
        return true;
      }

      // Otherwise read the setting from the current page
      return get(this, 'currentPage.options.grid_disabled', false);
    },
    loadAssetsFromContentApi() {
      return this.studioAppIsRunning
        && get(this, 'draft.state') === 'draft'
        && this.region === 'US'
        && this.draftIsDeCreated
        && !getLocalData();
    },
    modalCanvases: (state) => (state.draft?.modals || []),
    modulesOrderedByZPosition() {
      return chain(this)
        .get('currentPage.modules', [])
        .cloneDeep()
        .sortBy(['layout.z_position', 'id'])
        .value();
    },
    getModulesByModalIdOrderedByZPosition: (state) => (modalId) => chain(state)
      .get('draft.modals')
      .find((modal) => modal.id === modalId)
      .get('modules', [])
      .cloneDeep()
      .sortBy(['layout.z_position', 'id'])
      .value(),
    getModulesByPageIdOrderedByZPosition: (state) => (pageId) => chain(state)
      .get('draft.pages')
      .find((page) => page.id === pageId)
      .get('modules', [])
      .cloneDeep()
      .sortBy(['layout.z_position', 'id'])
      .value(),
    getOrderedModules() {
      const mods = cloneDeep(this.currentPage?.modules);
      if (mods) {
        utils.sortModules(mods);
      }
      return mods;
    },
    getOrderedModulesByModalId: (state) => (modalId) => {
      const modal = get(state, 'draft.modals', []).find((m) => m.id === modalId);
      return modal
        ? utils.sortModules(cloneDeep(modal.modules))
        : [];
    },
    getOrderedModulesByPageId: (state) => (pageId) => {
      const page = get(state, 'draft.pages', []).find((draftPage) => draftPage.id === pageId);
      return page
        ? utils.sortModules(cloneDeep(page.modules))
        : [];
    },
    displayedPageId(state) {
      if (!this.pagesSorted || !isNumber(state.editorCarouselPageIndex)) return null;
      return this.pagesSorted[state.editorCarouselPageIndex]?.id;
    },
    displayedModuleIds(state) {
      if (!this.pagesSorted || !isNumber(state.editorCarouselPageIndex)) return new Set();
      const moduleIds = new Set();
      if (state.currentModalCanvasId) {
        const modal = get(state, 'draft.modals', []).find((m) => m.id === state.currentModalCanvasId);
        (modal?.modules || []).forEach((mod) => {
          moduleIds.add(mod.id);
        });
      }
      const modules = this.pagesSorted[state.editorCarouselPageIndex]?.modules || [];
      modules.forEach((mod) => {
        moduleIds.add(mod.id);
      });
      return moduleIds;
    },
    currentlyEditing: (state) => (componentId) => {
      if (!state.session || !state.session.lockedComponents) return null;
      const editing = state.session.lockedComponents[componentId];
      if (!editing) return null;
      return state.connectedUsers.find((u) => u.id === editing);
    },
    isLocked() {
      return (componentId) => {
        const currentlyEditingUser = this.currentlyEditing(componentId);
        return !!currentlyEditingUser && !currentlyEditingUser.isCurrentUser;
      };
    },
    currentUserConnection(state) {
      return find(state.connectedUsers, (user) => user.isCurrentUser);
    },
    isHost: (state) => {
      const hostUser = find(state.connectedUsers, (user) => user.isHost);
      const currentUser = find(state.connectedUsers, (user) => user.isCurrentUser);
      return hostUser && currentUser && currentUser.id === hostUser.id;
    },
    /**
      Picks a host user from the set of connected users that are currently viewing
      the current page. Returns true if the current user is the selected host user.
     */
    isCurrentPageHost() {
      const currentPageId = get(this, 'currentPage.id');
      return this.isPageHost(currentPageId);
    },
    /*
      Determines if the current user is the host for the provided page id.
    */
    isPageHost: (state) => (pageId) => {
      if (!pageId) return false;
      const pageHost = chain(state)
        .get('session.currentPageByUserId')
        .toPairs()
        .filter((pair) => last(pair) === pageId)
        .map((pair) => find(state.connectedUsers, (user) => user.id === first(pair)))
        .filter((user) => user)
        .sortBy('connectionTime')
        .first()
        .value();
      return get(pageHost, 'isCurrentUser', false);
    },
    isModuleClipboardEmpty(state) {
      return isEmpty(state.moduleClipboard);
    },
  },
  actions: {
    [types.SET_EDITOR_CAROUSEL_IS_ANIMATED](isAnimated) {
      this.editorCarouselIsAnimated = isAnimated;
    },
    [types.SET_ASSIGNMENT_ID](id) {
      this.assignmentId = id;
    },
    [types.SET_IS_PREVIEW_MODE](isPreviewMode) {
      this.isPreviewMode = isPreviewMode;
    },
    [types.SET_OVERLAY_THUMBNAIL](url) {
      this.overlayThumbnail = url;
    },
    async [types.TOGGLE_EDITOR_DRAWER]({ drawer, open }) {
      // If we've passed in a new open state, use that. Otherwise, toggle the drawer state
      const newDrawerState = open === undefined
        ? !this.editorDrawers[drawer]
        : open;

      if (!newDrawerState) {
        const confirmationStore = useConfirmationStore();
        const confirmAction = `${types.TOGGLE_EDITOR_DRAWER}:${drawer}:close`;
        if (!(await confirmationStore[types.CONFIRM_ACTION](confirmAction))) {
          return;
        }
      }

      this.editorDrawers[drawer] = newDrawerState;
    },
    [types.SET_CURRENT_PAGE_FOR_CONNECTED_USER](pageId) {
      if (get(sharedDoc, 'isConnected')) {
        sharedDoc.change((yDoc) => {
          const currentUserId = get(sharedDoc, 'currentUser.id');
          if (currentUserId) {
            yDoc.get('session').get('currentPageByUserId').set(currentUserId, pageId);
          }
        }, LOCAL_TRANSACTION);
      }
    },
    /*
      IMPORTANT: Always use this action to set the current page id.
      commit(types.VIEW_PAGE_BY_ID) should not be called outsite this action
      because this action sets the current page for this user in the sharedDoc.
    */
    async [types.VIEW_PAGE_BY_ID](id) {
      if (id !== this.currentPageId) {
        const confirmationStore = useConfirmationStore();
        if (!(await confirmationStore[types.CONFIRM_ACTION](types.VIEW_PAGE_BY_ID))) {
          return;
        }
      }

      const pages = get(this, 'draft.pages', []);
      const newPage = pages.find((page) => page.id === id);
      if (newPage) {
        // Reset drawers if necessary
        if (newPage.options?.asset_id) {
          await this[types.TOGGLE_EDITOR_DRAWER]({ drawer: 'addModule', open: false });
        }

        this.currentPageId = id;
        this.currentModalCanvasId = null;
        this[types.SET_CURRENT_PAGE_FOR_CONNECTED_USER](id);
      } else {
        const firstPage = chain(pages)
          .sortBy('sort_index', 'id')
          .first()
          .value();
        if (firstPage) {
          // Reset drawers if necessary
          if (firstPage.options?.asset_id) {
            await this[types.TOGGLE_EDITOR_DRAWER]({ drawer: 'addModule', open: false });
          }

          this.currentPageId = firstPage.id;
          this.currentModalCanvasId = null;
          this[types.SET_CURRENT_PAGE_FOR_CONNECTED_USER](id);
        }
      }
    },
    async [types.VIEW_MODAL_CANVAS_BY_ID](id) {
      const confirmationStore = useConfirmationStore();
      if (!(await confirmationStore[types.CONFIRM_ACTION](types.VIEW_MODAL_CANVAS_BY_ID))) {
        return;
      }
      const modals = get(this, 'draft.modals', []);
      const newModal = modals.find((modal) => modal.id === id);
      if (newModal) {
        this.currentModalCanvasId = id;
      }
    },
    [types.CLOSE_MODAL_CANVAS]() {
      this.currentModalCanvasId = null;
    },
    async [types.VIEW_PREV_PAGE]() {
      // get sorted pages
      const pages = this.pagesSorted;
      const currentPageIdx = pages.findIndex((page) => (page.id === this.currentPageId));

      // Exit if we're on the first page.
      if (currentPageIdx === 0) return;
      if (this.editorIsFullscreen) {
        /*
          Find the first previous page that is not marked as hidden
          when displaying in fullscreen mode.
        */
        const previousPage = chain(pages)
          .take(currentPageIdx)
          .findLast((page) => !get(page, 'options.fullscreen_skip'))
          .value();
        if (previousPage) {
          await this[types.VIEW_PAGE_BY_ID](previousPage.id);
        }
      } else {
        await this[types.VIEW_PAGE_BY_ID](pages[currentPageIdx - 1].id);
      }
    },
    async [types.VIEW_NEXT_PAGE]() {
      // get sorted pages
      const pages = this.pagesSorted;
      const currentPageIdx = pages.findIndex((page) => (page.id === this.currentPageId));

      // Exit if we're on the last page.
      if (currentPageIdx === get(this, 'draft.pages.length') - 1) return;
      if (this.editorIsFullscreen) {
        /*
          Find the next page that is not marked as hidden
          when displaying in fullscreen mode.
        */
        const nextPage = chain(pages)
          .slice(currentPageIdx + 1)
          .find((page) => !get(page, 'options.fullscreen_skip'))
          .value();
        if (nextPage) {
          await this[types.VIEW_PAGE_BY_ID](nextPage.id);
        }
      } else {
        await this[types.VIEW_PAGE_BY_ID](pages[currentPageIdx + 1].id);
      }
    },
    async [types.VIEW_CLOSEST_FULLSCREEN_VISIBLE_PAGE]() {
      const closestVisiblePage = this.closestFullscreenVisiblePage();
      if (closestVisiblePage) {
        await this[types.VIEW_PAGE_BY_ID](closestVisiblePage.id);
      }
    },
    async [types.SEND_API_REQUEST](patchList) {
      const toastStore = useToastStore();
      // get the draft id
      const draftId = this.draft?.id;

      if (draftId) {
        // toggle saving
        this[types.UPDATE_SAVING](true);

        // make api req
        const result = await this.api.patch(`/drafts/${draftId}`, patchList);

        if (result && result.status !== 200) {
          const errorMessage = get(result, 'response.data.meta.message');
          toastStore[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMessage });
        }

        // toggle saving
        this[types.UPDATE_SAVING](false);
      }

      // success. errors will reject the promise, which will propogate to the poll func
      return true;
    },
    [types.START_POLL]() {
      // curry
      const send = (patchList) => this[types.SEND_API_REQUEST](patchList);
      // hook em up
      queue.poll(send, 1000);
    },
    [types.KILL_POLL]() {
      // hook em up
      queue.clear();
      queue.kill();
    },
    [types.SET_STUDENT_VIEW](studentView) {
      this.studentView = studentView;
    },
    async [types.GET_TEMPLATES]() {
      const result = await this.api.get('/templates/');

      if (result && result.status === 200) {
        this.templates = result.data.templates;
      } else {
        // Error handling
        this.templates = [];
      }
    },
    [types.UPDATE_PAGE_HEIGHT](height) {
      if (height) {
        // get the current page
        const { options } = this.currentPage;
        options.height = height;
        this[types.UPDATE_PAGE_OPTIONS]({ options, pageId: this.currentPageId });
      }
    },
    [types.CACHE_GRID_MATRIX](payload) {
      if (this.gridIsDisabled) return;
      const modules = payload.modules || this.currentPage.modules;
      const moduleLayouts = modules.map((mod) => mod.layout);
      const { columns } = payload;

      // Create empty matrix
      const matrix = Array.from({ length: 11 }, () => (
        Array.from({ length: columns }, () => 0)
      ));

      // Fill matrix
      moduleLayouts.forEach((element) => {
        const initX = element.x_position;
        const initY = element.y_position;
        for (let x = element.x_position; x < initX + element.width; x += 1) {
          for (let y = element.y_position; y < initY + element.height; y += 1) {
            if (!matrix[y] && this.draft.options.type === 'board') {
              /*
                Allow "board"-type creations to expand vertically.
                Add missing rows up to the needed y position.
              */
              range(matrix.length, y + 1).forEach((rowIndex) => {
                if (!matrix[rowIndex]) {
                  matrix[rowIndex] = Array.from({ length: columns }, () => 0);
                }
              });
            }
            if (matrix[y]) matrix[y][x] = 1;
          }
        }
      });

      if (this.draftType === 'board') {
        // update page height if needed
        const pageHeight = this.currentPage.options?.height;
        const bottomYValue = bottomYPosition(this.currentPage.modules);

        if (!pageHeight || pageHeight < bottomYValue) {
          this[types.UPDATE_PAGE_HEIGHT](bottomYValue);
        }
      }
      this.gridMatrix = matrix;
    },
    [types.DISABLE_GRID_FOR_PAGE]({ pageId }) {
      if (!sharedDoc) return;
      sharedDoc.change((yDoc) => {
        const yPage = yjsHelper.find(
          yDoc.get('draft').get('pages'),
          (page) => page.get('id') === pageId,
        );

        if (yPage) {
          const yPageOptions = yPage.get('options');

          // Update the page options
          this[types.UPDATE_PAGE_OPTIONS]({
            options: {
              ...(yPageOptions
                ? yPageOptions.toJSON()
                : {}),
              grid_disabled: true,
            },
            pageId,
          });

          // Convert the page's modules from row/column layout to no-grid layout
          yPage.get('modules').forEach((yModule) => {
            const noGridLayout = layoutToPixels(yModule.get('layout').toJSON());
            yModule.set('layout', yjsHelper.toY(noGridLayout));
          });
        }
      }, LOCAL_TRANSACTION);
    },
    [types.CLEAR_DRAFT]() {
      if (this.draft) {
        /*
          Clearing the current draft will flag it as 'unloaded' which
          will prevent it from being displayed in the UI. Keeping the draft in
          memory prevents any temporary errors in computed properties that might
          occur throughout the app while a new draft is being loaded.
        */
        this.draft.unloaded = true;
      }
      this.accessLevelForDraft = null;
    },
    [types.CLOSE_EDITOR_DRAWERS]() {
      Object.keys(this.editorDrawers).forEach((drawer) => {
        this.editorDrawers[drawer] = false;
      });
    },
    async [types.GET_CREATION](payload = {}) {
      const appStore = useAppStore();
      const assetStore = useAssetStore();
      const assessmentStore = useAssessmentStore();
      const trackingStore = useTrackingStore();
      const errorStore = useErrorStore();
      const userStore = useUserStore();
      try {
        appStore[types.UPDATE_LOADING](true);
        this[types.CLOSE_EDITOR_DRAWERS]();

        // generate the draft path
        let path;
        const {
          allowAttempt,
          homeworkId,
          id,
          preventRedirect,
          type,
          pageId,
          userId,
          ignoreGetAttemptError,
          useRevision,
        } = payload;

        if (!id) return;

        // Grab by reference id or creation id
        if (isGuid(id) || type) {
          path = `/creations/${id}?reference_type=${type || 'asset'}`;
        } else {
          path = `/creations/${id}?`;
        }

        /*
          grab the activity summary only if we are tracking metadata
          and the current user is authenticated.
        */
        if (
          trackingStore.progressTracking?.enabled
            && !trackingStore.isAnonymous
            && userStore.user
        ) {
          path += '&activity_summary=1';

          if (homeworkId) {
            path += `&assignment_id=${homeworkId}`;
          }
        }

        // get the draft
        const result = await this.apiProgressBar.get(path);

        if (result.status === 200) {
          const { creation } = result.data;
          const creationRevisionAssetReference = creation.references
            .find((ref) => ref.reference_type === 'asset' && ref.reference_subtype !== 'head');
          const creationPointerAssetReference = creation.references
            .find((ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'head');
          const isActivityTemplate = creation.options.is_template
            && creation.options.template_type === 'activity';

          // Redirect to a route with the pointer asset id instead of the revision asset id
          // unless we're viewing an assigned board or an activity template. This also
          // redirects if we've accessed a creation using its mongo id.
          if (!(isActivityTemplate && window.location.pathname.includes('activity-templates'))
            && !preventRedirect
            && creationPointerAssetReference
            && id !== creationPointerAssetReference.reference_id
            && !(
              creationRevisionAssetReference
                && id === creationRevisionAssetReference.reference_id
                && useRevision
            )) {
            const correctId = useRevision
              ? creationRevisionAssetReference.reference_id
              : creationPointerAssetReference.reference_id;

            window.location.href = window.location.href.replace(`id=${id}`, `id=${correctId}`);
            return;
          }

          // set the assignment id
          if (homeworkId) {
            this[types.SET_ASSIGNMENT_ID](homeworkId);
          }

          // Make sure the creation is renderable
          utils.validateModuleLayouts(creation);

          if (isActivityTemplate && !userStore.isDeUser
            && !window.location.pathname.includes('activity-templates')) {
            // Non-editorial users should not be able to view activity templates in the editor
            errorStore[types.SET_ERROR]({
              active: true,
              error: {
                component: 'ActivityTemplatePermissionsError',
                options: {
                  creation,
                },
              },
            });
            return;
          }

          // allowAttempt can be overridden for teachers looking at lessons with full slide teis
          // regular studio quizzes cannot be interacted it without a homework guid
          const teacherAttemptOverride = (!userStore.userIsStudent && creation.tei_page_asset_ids);
          const enableAttempts = teacherAttemptOverride || allowAttempt;

          if (creationRevisionAssetReference && enableAttempts) {
            // For assigned boards with interactive modules or full-slide TEIs,
            // retrieve the attempt
            if (utils.draftHasInteractiveModules(creation) || creation.tei_page_asset_ids?.length) {
              await assessmentStore[types.GET_ATTEMPT]({
                assessmentId: creationRevisionAssetReference.reference_id,
                homeworkId,
                userId,
                ignoreError: ignoreGetAttemptError,
              });
            } else if (homeworkId) {
              try {
                // For assigned boards without interactive modules, get the assigned status
                const assignmentResponse = await this.deApi(`/assignments/${homeworkId}`);
                const assignmentStatus = get(assignmentResponse, 'data.status');

                if (COMPLETED_ASSIGNMENT_STATUSES.indexOf(assignmentStatus) !== -1) {
                  creation.assignmentIsComplete = true;

                  // disable tracking if the assignment is complete
                  trackingStore[types.DISABLE_ALL_EVENTS]();
                }

                // if the status is not-started and the viewer is the assignee, mark as started
                if (assignmentStatus === NOT_STARTED_ASSIGNMENT_STATUS) {
                  await this.deApi.post(
                    `/assignments/${homeworkId}/update_status`,
                    {
                      status_id: IN_PROGRESS_ASSIGNMENT_STATUS_ID,
                    },
                    {
                      headers: {
                        'X-Token': appStore.apiToken,
                      },
                    },
                  );
                }
              } catch (e) {
                // if you are a student that this board has not been assigned to, show an error
                if (e.response?.status === 400 && userStore.userIsStudent) {
                  errorStore[types.SET_ERROR]({
                    active: true,
                    error: e.response.data,
                  });
                }
              }
            }
          }

          // disable events if the creation is not de-created
          if (!creation.sync_info.is_de_created) {
            trackingStore[types.DISABLE_ALL_EVENTS]();
          }

          // Set the draft and clear the draft access level to prevent hangover data
          this.draft = creation;
          this.accessLevelForDraft = null;
          if (pageId) {
            await this[types.VIEW_PAGE_BY_ID](pageId);
          } else {
            const pages = this.pagesSorted;
            if (pages && pages[0]) {
              await this[types.VIEW_PAGE_BY_ID](pages[0].id);
            }
          }
          assetStore[types.CACHE_ASSETS_AND_UPLOADS]();
          assessmentStore[types.CACHE_TEIS]();

          // Send a clicklog event for the creation
          if (creationPointerAssetReference) {
            trackingStore[types.SEND_CLICKLOG_EVENT]({
              ids: [creationPointerAssetReference.reference_id],
            });
          }

          // store the activity summary
          if (this.draft.activity_summary && !trackingStore.isAnonymous) {
            trackingStore[types.SET_ACTIVITY_SUMMARY](this.draft.activity_summary);
          }
        } else {
          throw result;
        }
      } catch (e) {
        // Error handling
        this[types.CLEAR_DRAFT]();
        errorStore[types.SET_ERROR]({
          active: true,
          error: e?.response?.data || e,
        });
      } finally {
        appStore[types.UPDATE_LOADING](false);
      }
    },
    async [types.MARK_AS_COMPLETE]({ homeworkId }) {
      if (!homeworkId) return;
      try {
        const appStore = useAppStore();
        const response = await this.deApi.post(`/assignments/${homeworkId}/update_status`, {
          status_id: 2, // SUBMITTED
        }, {
          headers: {
            'X-Token': appStore.apiToken,
          },
        });

        if (response.status === 200) {
          this.draft.assignmentIsComplete = true;
        } else {
          throw response;
        }
      } catch (e) {
        const errorMessage = get(e, 'response.data.meta.message', e);
        const toastStore = useToastStore();
        toastStore[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMessage });
      }
    },
    async [types.CREATE_DRAFT](payload) {
      const appStore = useAppStore();
      const errorStore = useErrorStore();
      let newDraft;
      const {
        isTemplate,
        originAssetId,
        pages,
        type,
      } = payload;
      appStore[types.UPDATE_LOADING](true);

      const postUrl = originAssetId ? `/drafts/?origin_asset_id=${originAssetId}` : '/drafts/';

      // Create the draft
      const result = await this.apiProgressBar.post(postUrl,
        {
          name: utils.defaultDraftName(type),
          options: {
            type,
            is_template: isTemplate || undefined,
            template_type: isTemplate ? 'activity' : undefined,
          },
          pages: pages || [utils.newPageTemplate(type)],
        },
        {
          headers: {
            'X-Token': appStore.apiToken,
          },
        });

      // Error handling
      if (result.status === 200) {
        appStore[types.UPDATE_LOADING](false);
        newDraft = result.data.draft;
      } else {
        errorStore[types.SET_ERROR]({
          active: true,
          error: get(result, 'response.data'),
        });
      }

      return newDraft;
    },
    async [types.COPY_CREATION](payload) {
      const appStore = useAppStore();
      const assetStore = useAssetStore();
      const assessmentStore = useAssessmentStore();

      const {
        type,
        id,
        commitOnSuccess,
        title,
        editOnSuccess,
      } = payload;

      let newDraft;
      const overrides = {};

      // let's craft a name
      if (title) {
        overrides.name = title;
      } else if (this.draft?.name) {
        let newTitle = '';
        const nameRegex = /^Copy \(([0-9]+)\) (.*?)$/i;
        const nameMatch = this.draft.name.match(nameRegex);

        if (nameMatch) {
          newTitle = `Copy (${parseInt(nameMatch[1], 10) + 1}) ${nameMatch[2]}`;
        } else {
          newTitle = `Copy (1) ${this.draft.name}`;
        }

        // Make a new board title. i18next escapes its strings by default, but since
        // the BE already escapes the board title once, this prevents it from being
        // double-escaped
        let copiedName = i18next.t('%(boardTitle)s', {
          boardTitle: newTitle,
          interpolation: { escapeValue: false },
        });

        if (copiedName.length >= 100) {
          // Prevent board names longer than 100 characters, since those will
          // have trouble being published or assigned
          copiedName = truncate(copiedName, { length: 100 });
        }

        // add the name to overrides
        overrides.name = copiedName;
      }

      // Create a new draft based on the passed id
      const result = await this.api.post(
        `/drafts/?source_${type}_id=${id}`, // e.g. '/drafts/?source_creation_id=GUID'
        overrides,
        {
          headers: {
            'X-Token': appStore.apiToken,
          },
        },
      );

      if (result.status === 200) {
        newDraft = result.data.draft;
      } else {
        // Error handling
        throw result;
      }

      if (commitOnSuccess) {
        this.draft = newDraft;
        const pages = this.pagesSorted;
        this[types.VIEW_PAGE_BY_ID](pages[0].id);
        assetStore[types.CACHE_ASSETS_AND_UPLOADS]();
        assessmentStore[types.CACHE_TEIS]();
      }

      if (editOnSuccess) {
        // grab the new pointer
        const reference = newDraft.references.find(ref => ref.reference_subtype === 'head');

        // open editing session
        if (reference) {
          await this[types.OPEN_EDITING_SESSION]({
            draftId: reference.reference_id,
            pageId: newDraft.pages[0].id,
          });
        }
      }

      return newDraft;
    },
    async [types.RESTORE_CREATION]({ creation, type = 'creation' }) {
      const url = type === 'creation'
        ? `/creations/${creation.id}`
        : `/drafts/${creation.id}`;
      const result = await this.api.patch(url, [{
        op: 'replace',
        path: '/is_deleted',
        value: false,
      }]);
      if (result.status !== 200) throw result;
    },
    [types.SET_ACTIVE_CANVAS_NAME](canvasName) {
      this.activeCanvasName = canvasName;
    },
    [types.SET_EDITOR_CAROUSEL_PAGE_INDEX](pageIndex) {
      this.editorCarouselPageIndex = pageIndex;
    },
    [types.COPY_MODULE]({ module, canvasName }) {
      const toastStore = useToastStore();
      // Ensure existing ids are removed before adding the module.
      // This ensures a new id will be generated.
      const mod = utils.removeModuleIds(cloneDeep(module));

      this.moduleClipboard = {
        module: mod,
        canvasName,
        gridIsDisabled: canvasName !== 'notes' && this.gridIsDisabled,
      };

      toastStore[types.SET_STUDIO_TOAST]({
        type: 'success',
        position: 'top',
        message: i18next.t('Copied to Clipboard!'),
      });
    },
    [types.PASTE_MODULE_FROM_CLIPBOARD](
      { canvasName, moduleToReplace, clipboardData },
    ) {
      const assetStore = useAssetStore();
      // We can paste a module from Pinia or from the device clipboard.
      // If we have neither, exit.
      const sourceData = clipboardData || this.moduleClipboard;
      if (!sourceData) return;

      const mod = cloneDeep(sourceData.module);

      if (moduleToReplace) {
        mod.layout = moduleToReplace.layout;

        this[types.REPLACE_MODULE]({
          canvasName,
          payload: mod,
          replacedModuleId: moduleToReplace.id,
        });
      } else {
        const payload = {
          canvasName,
        };

        // if we're jumping across canvases, we need to put the modules on the bottom
        if (canvasName === 'notes' && sourceData.canvasName !== 'notes') {
          payload.addToBottom = true;
        }

        const position = {
          x: mod.layout.x_position,
          y: mod.layout.y_position,
        };

        const sourceHasGridDisabled = sourceData.gridIsDisabled;
        const targetHasGridDisabled = canvasName !== 'notes' && this.gridIsDisabled;
        if ((canvasName !== sourceData.canvasName
          && [canvasName, sourceData.canvasName].includes('notes'))
          || sourceHasGridDisabled !== targetHasGridDisabled) {
          // If we're moving between the notes canvas and another canvas,
          // or between a gridded canvas and a no-grid canvas,
          // use the default layout
          const typeKey = `${camelCase(mod.type)}ModuleDefault`;

          const assetId = get(mod.options, 'asset_id');
          const asset = assetStore.assets[assetId];

          if (moduleDefaults[typeKey]) {
            const defaultVals = moduleDefaults[typeKey](targetHasGridDisabled, asset);

            // replace width and height with the defaults
            mod.layout = defaultVals.layout;
          }
        }
        payload.module = mod;

        /*
          When copy and pasting from one canvas to another, try
          to preserve the module's position. If the module's position
          is inside the bounds of the target canvas, then paste the
          module to its original position.
        */
        if (sourceHasGridDisabled && targetHasGridDisabled) {
          let canvasWidth;
          let canvasHeight;
          if (canvasName === 'modalModules') {
            canvasWidth = MODAL_CANVAS_WIDTH;
            canvasHeight = MODAL_CANVAS_HEIGHT;
          } else {
            /*
              TODO: When boards have the grid disabled, then we'll probably
              want to pass in the canvas dimensions as a parameter to this
              function since board dimensions are not fixed and are dynamic.
            */
            canvasWidth = EDITOR_CANVAS_WIDTH;
            canvasHeight = EDITOR_CANVAS_HEIGHT;
          }
          const right = position.x + mod.layout.width;
          const bottom = position.y + mod.layout.height;
          const leftInside = position.x >= 0 && position.x <= canvasWidth;
          const rightInside = right >= 0 && right <= canvasWidth;
          const topInside = position.y >= 0 && position.y <= canvasHeight;
          const bottomInside = bottom >= 0 && bottom <= canvasHeight;
          const moduleInsideCanvas = (topInside && leftInside)
            || (topInside && rightInside)
            || (bottomInside && rightInside)
            || (bottomInside && leftInside);

          if (moduleInsideCanvas) {
            payload.position = position;
          }
        }

        this[types.PASTE_MODULE](payload);
      }
    },
    async [types.PASTE_MODULE_FROM_GUID]({ guid, canvasName }) {
      const toastStore = useToastStore();
      try {
        // Make our api call
        const apiResults = await this.deApi(`/assets/${guid}`);
        const assetSelection = [apiResults.data.asset][0];
        const gridIsOff = canvasName !== 'notes' && this.gridIsDisabled;

        if (canvasName !== 'notes') {
          // Scan the grid if we're in the editor canvas
          try {
            const newModule = moduleDefaults.assetModuleDefault(gridIsOff, assetSelection);
            newModule.options.asset_id = assetSelection.id;

            const modulesOrderedByZ = canvasName === 'modalModules'
              ? this.getModulesByModalIdOrderedByZPosition(this.currentModalCanvasId)
              : this.modulesOrderedByZPosition;

            let destinationCoords;
            if (gridIsOff) {
              const canvasInfo = canvasName === 'modalModules'
                ? {
                  width: MODAL_CANVAS_WIDTH,
                  height: MODAL_CANVAS_HEIGHT,
                  bleed: MODAL_CANVAS_PLACE_MODULE_BLEED,
                }
                : { width: EDITOR_CANVAS_WIDTH, height: EDITOR_CANVAS_HEIGHT };

              destinationCoords = placeModule(
                modulesOrderedByZ,
                newModule,
                canvasInfo,
              );
            } else {
              destinationCoords = scanGrid(this.gridMatrix, newModule, this.draftType === 'board');
            }

            Object.assign(newModule.layout, {
              x_position: destinationCoords.x,
              y_position: destinationCoords.y,
              height: destinationCoords.height || newModule.layout.height,
            });

            if (gridIsOff) {
              newModule.layout.z_position = createTopZPosition(modulesOrderedByZ);
            }

            // The ADD_MODULE action takes care of copying data and assigning a new id
            this[types.ADD_MODULE]({
              canvasName,
              payload: newModule,
            }).then((newModuleId) => {
              bus.emit('activeModule', {
                canvasName,
                id: newModuleId,
              });
            });
          } catch {
            bus.emit('invalidPlacementAttempted');
          }
        } else {
          const newModule = moduleDefaults.assetModuleDefault(gridIsOff, assetSelection);
          // Add module to the bottom of the notes canvas
          newModule.options.asset_id = assetSelection.id;
          newModule.layout.y_position = bottomYPosition(this.currentPage.notes);

          this[types.ADD_MODULE]({
            canvasName,
            payload: newModule,
          }).then((newModuleId) => {
            bus.emit('activeModule', {
              canvasName,
              id: newModuleId,
            });
          });
        }
        const successMsg = i18next.t('Asset Added Successfully');
        toastStore[types.SET_STUDIO_TOAST]({ type: 'success', message: successMsg });
      } catch (err) {
        const errorMsg = i18next.t('Paste failed: invalid asset');
        toastStore[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMsg });
      }
    },
    [types.PASTE_MODULE](
      {
        module,
        canvasName,
        addToBottom,
        position,
      },
    ) {
      const toastStore = useToastStore();
      const mod = module;
      const disallowedNotesModules = [
        'prompt',
        'single_selection',
        'multiple_selection',
        'block',
      ];

      if (canvasName !== 'notes') {
        try {
          // Auto advance video option not allowed on the modal canvas.
          if (canvasName === 'modalModules') {
            if (get(mod, 'options.auto_advance')) {
              mod.options.auto_advance = false;
            }
          }

          if (this.gridIsDisabled) {
            const modulesOrderedByZ = canvasName === 'modalModules'
              ? this.getModulesByModalIdOrderedByZPosition(this.currentModalCanvasId)
              : this.modulesOrderedByZPosition;
            const canvasInfo = canvasName === 'modalModules'
              ? {
                width: MODAL_CANVAS_WIDTH,
                height: MODAL_CANVAS_HEIGHT,
                bleed: MODAL_CANVAS_PLACE_MODULE_BLEED,
              }
              : { width: EDITOR_CANVAS_WIDTH, height: EDITOR_CANVAS_HEIGHT };

            if (position) {
              canvasInfo.defaultPosition = position;
            }

            const coordinates = placeModule(
              modulesOrderedByZ,
              mod,
              canvasInfo,
            );
            mod.layout.x_position = coordinates.x;
            mod.layout.y_position = coordinates.y;
            mod.layout.z_position = createTopZPosition(modulesOrderedByZ);
          } else {
            // Scan the grid if we're in the editor canvas
            const { x, y, height } = scanGrid(this.gridMatrix, mod, this.draftType === 'board');
            mod.layout.x_position = x;
            mod.layout.y_position = y;
            mod.layout.height = height;
            // Rotation not allowed if grid is enabled
            mod.layout.rotate_degree = undefined;
          }

          // The ADD_MODULE action takes care of copying data and assigning a new id
          this[types.ADD_MODULE]({
            canvasName,
            payload: mod,
          }).then((newModuleId) => {
            bus.emit('activeModule', {
              canvasName,
              id: newModuleId,
            });
            this[types.CLEAR_CURRENT_USER_EDITING]();
            this[types.UPDATE_CURRENTLY_EDITING]({
              [newModuleId]: this.currentUserConnection?.id,
            });
          });
        } catch {
          bus.emit('invalidPlacementAttempted');
        }
      } else if (!disallowedNotesModules.includes(mod.type)) {
        // Video options not allowed in notes modules.
        if (get(mod, 'options.auto_advance')) {
          mod.options.auto_advance = false;
        }
        if (get(mod, 'options.auto_play')) {
          mod.options.auto_play = false;
        }
        if (get(mod, 'options.loop_video')) {
          mod.options.loop_video = false;
        }
        // Word tracking not allowed in the notes tab.
        if (get(mod, 'options.word_tracking')) {
          utils.removeWordTracking(mod);
        }
        // Rotation not allowed in notes
        mod.layout.rotate_degree = undefined;

        if (addToBottom) {
          // place at the bottom
          mod.layout.y_position = bottomYPosition(this.currentPage.notes);
        } else {
          mod.layout.y_position += mod.layout.height;
        }

        this[types.ADD_MODULE]({
          canvasName,
          payload: mod,
        }).then((newModuleId) => {
          bus.emit('activeModule', {
            canvasName,
            id: newModuleId,
          });

          this[types.CLEAR_CURRENT_USER_EDITING]();
          this[types.UPDATE_CURRENTLY_EDITING]({
            [newModuleId]: this.currentUserConnection?.id,
          });
        });
      } else {
        const errorMsg = i18next.t('This module cannot be added to Teacher Notes');
        toastStore[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMsg });
      }
    },
    [types.DUPLICATE_MODULE]({ module, canvasName }) {
      // Ensure existing ids are removed before adding the module.
      // This ensures a new id will be generated.
      const mod = utils.removeModuleIds(cloneDeep(module));

      this[types.PASTE_MODULE]({
        module: mod,
        canvasName,
        position: {
          x: mod.layout.x_position,
          y: mod.layout.y_position,
        },
      });
    },
    [types.SET_EDITOR_STATUS_MESSAGE](payload) {
      this.editorStatusMessage = payload;
    },
    [types.CACHE_URL_REQUEST](payload) {
      this.urlRequestCache[payload.url] = payload.promise;
    },
    [types.TRACK_THUMBNAIL_LOADER](payload) {
      this.thumbnailLoaders.push(payload);
    },
    [types.CLEAR_THUMBNAIL_LOADERS]() {
      this.thumbnailLoaders = [];
    },
    [types.SET_TRACK_THUMBNAIL_LOADERS](track) {
      this.trackThumbnailLoaders = track;
    },
    [types.UPDATE_TEI_ERRORS]({ id, error }) {
      this.teiErrors[id] = error;
    },
    [types.SET_EDITOR_IS_FULLSCREEN](payload) {
      const modalStore = useModalStore();
      // Close modals when entering or exiting fullscreen mode
      modalStore[types.CLEAR_MODALS]();
      modalStore[types.SET_MY_CONTENT_MODAL_OPTIONS]({
        config: {
          isActive: false,
        },
      });

      // Toggle view mode preview on and off if in edit mode
      if (payload.mode === 'EDIT' || (!payload.isFullscreen && this.editorViewModePreview)) {
        this[types.SET_EDITOR_VIEW_MODE_PREVIEW](payload.isFullscreen);
      }

      this.editorIsFullscreen = payload.isFullscreen;
    },
    [types.SET_EDITOR_VIEW_MODE_PREVIEW](editorViewModePreview) {
      this.editorViewModePreview = editorViewModePreview;
    },
    [types.SET_EDITOR_SNAP_GRID](snapGrid) {
      this.editorSnapGrid = snapGrid;
    },
    [types.SET_TOP_NAV_DROPDOWN_IS_OPEN](payload) {
      this.topNavDropdownIsOpen = payload.isOpen;
    },
    [types.SET_TOP_NAV_DRAWER_IS_OPEN](isOpen) {
      this.topNavDrawerIsOpen = isOpen;
    },
    async [types.DELETE_DRAFT](payload) {
      /*
        Only delete the draft if deleting the creation succeeds to
        help avoid orphaned creations. Creations can only be deleted by
        deleting the draft in the UI.
      */
      const urls = [
        `/drafts/${payload.id}`,
      ];

      // should we also delete the creation?
      if (payload.published) {
        urls.push(`/creations/${payload.id}`);
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const url of urls) {
        // eslint-disable-next-line no-await-in-loop
        const result = await this.api.delete(url);
        if (isError(result)) {
          const errorStatus = get(result, 'response.status');
          /*
            If the resource doesn't exist or is already deleted, suppress the error.
            e.g. The associated creation may not exist. In this case continue with
            deleting the draft.
          */
          if (![404, 410].includes(errorStatus)) {
            throw result;
          }
        }
      }
    },
    /*
      **********************************************************************************
      Collaborative editing actions.
      TODO: Eventually, we will want to refactor the Editor into its own
      module that will contain these actions plus any others that
      are specific to the editor.
      Due to time constraints, this refactor will come at a later date and
      the editor and shared editing session will remain in the root module for now.
      **********************************************************************************
    */
    async [types.INIT_DRAFT](payload) {
      const { draft, pageId } = payload;
      const chatStore = useChatStore();
      const assetStore = useAssetStore();
      const assessmentStore = useAssessmentStore();
      this.draft = draft;
      this[types.SET_CONNECTED_USERS_COLORS](); // save the owner's editing color

      // get the pages sorted by sort_index
      const pages = this.pagesSorted;

      if (pageId && pages.find((page) => page.id === pageId)) {
        await this[types.VIEW_PAGE_BY_ID](pageId);
      } else {
        await this[types.VIEW_PAGE_BY_ID](pages[0].id);
      }
      assetStore[types.CACHE_ASSETS_AND_UPLOADS]();
      assessmentStore[types.CACHE_TEIS]();

      // By default, drafts have chat turned off. If it's already on, though,
      // we can open a chat session as well
      const chatEnabled = get(draft, 'options.chat_enabled');
      if (chatEnabled) {
        chatStore[types.GET_CHAT]({ draft });
      }
      chatStore[types.SET_CHAT_TOGGLE_ENABLED](chatEnabled);
    },
    async [types.GET_DRAFT]({
      draftId,
      isThumbnailGenerator,
      commitOnSuccess,
      pageId,
      type,
    }) {
      const userStore = useUserStore();
      const assetStore = useAssetStore();
      const assessmentStore = useAssessmentStore();
      const errorStore = useErrorStore();
      try {
        const path = (isGuid(draftId) || type)
          ? `/drafts/${draftId}?reference_type=${type || 'asset'}`
          : `/drafts/${draftId}`;

        const result = await this.apiProgressBar.get(path);

        if (get(result, 'status') === 200) {
          const { draft } = result.data;

          // If there aren't any pages, add one
          if (!draft.pages || draft.pages.length === 0) {
            draft.pages = [utils.newPageTemplate()];

            const patch = {
              op: 'replace',
              path: '/pages',
              value: draft.pages,
            };

            await this.api.patch(`/drafts/${draft.id}`, [patch]);
          }

          if (!draft.modals) {
            draft.modals = [];
          }

          utils.validateModuleLayouts(draft);

          if (draft.options.is_template && draft.options.template_type === 'activity'
            && !userStore.isDeUser && !window.location.pathname.includes('activity-templates')) {
            // Non-editorial users should not be able to edit activity templates
            const error = new Error();
            error.response = {
              data: {
                component: 'ActivityTemplatePermissionsError',
                options: {
                  creation: draft,
                },
              },
            };
            throw error;
          }

          // Make sure the draft is editable by the user
          if (['view', 'readonly'].includes(draft.access_level)) {
            const error = new Error('User not authorized to edit this draft');

            const draftPointerAssetReference = draft.references
              .find((ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'head');

            error.response = {
              data: {
                component: 'ViewPermissionsError',
                options: {
                  draftReferenceId: draftPointerAssetReference
                    && draftPointerAssetReference.reference_id,
                  draftType: draft.options.type,
                },
              },
            };
            throw error;
          }

          if (isThumbnailGenerator || commitOnSuccess) {
            // The thumbnail generator doesn't load drafts in collaborative editing mode;
            // we can set the draft directly without dealing with a yDoc
            this.draft = draft;
            if (pageId) {
              await this[types.VIEW_PAGE_BY_ID](pageId);
            } else {
              const pages = this.pagesSorted;
              await this[types.VIEW_PAGE_BY_ID](pages[0].id);
            }
            assetStore[types.CACHE_ASSETS_AND_UPLOADS]();
            assessmentStore[types.CACHE_TEIS]();
          }

          return draft;
        }
        throw result;
      } catch (error) {
        errorStore[types.SET_ERROR]({
          active: true,
          error: get(error, 'response.data', error),
        });
        throw error;
      }
    },
    async [types.GET_DRAFT_REFERENCES]({ draftId, type }) {
      const path = (isGuid(draftId) || type)
        ? `/drafts/${draftId}?reference_type=${type || 'asset'}`
        : `/drafts/${draftId}`;
      const result = await this.api.get(path, {
        headers: { 'X-Fields': 'is_deleted, references, access_level, id, options{type}, metadata{published_on}' },
      });
      if (get(result, 'status') === 200) {
        // All users work off the same yDoc, but since their access levels are
        // different, this must be stored separately
        this.accessLevelForDraft = result.data.draft.access_level;
        return result.data.draft;
      }
      throw result;
    },
    async [types.GET_SHARED_DOCUMENT](payload) {
      const draft = await this[types.GET_DRAFT](payload);
      const sharedDocData = {
        session: {
          lockedComponents: {},
          draftIsDirty: false,
          currentPageByUserId: {},
        },
        messages: {
          addModuleConflictMessages: [],
        },
        draft,
      };
      return sharedDocData;
    },
    [types.ADD_MODULE_CONFLICT_MESSAGE](moduleId) {
      sharedDoc.change((yDoc) => {
        const yMessage = yjsHelper.toY({
          id: uuid(),
          moduleId,
          displayed: false,
        });
        yDoc.get('messages').get('addModuleConflictMessages').push([yMessage]);
      }, LOCAL_TRANSACTION);
    },
    [types.UPDATE_MODULE]({ canvasName, payload, captureTimeout }) {
      this[types.UPDATE_MODULES]({ canvasName, modules: [payload], captureTimeout });
    },
    [types.UPDATE_MODULES]({
      canvasName,
      modules,
      captureTimeout,
      canUndo = true,
    }) {
      // Create an undo item for the updates.
      const updateItem = new DocumentChangeStackItem(sharedDoc, { captureTimeout });
      updateItem.tags.set('canvasName', canvasName);

      // Create an undoable change for module to be updated.
      modules.forEach((updatedModule) => {
        // Make a copy first.
        const mod = cloneDeep(updatedModule);

        // Make sure non-standard values are not present
        if (mod.options.display) {
          delete mod.options.display;
        }

        const getYModule = partial(utils.findYModuleById, canvasName);
        const yModule = getYModule(sharedDoc.document, mod.id);

        if (yModule) {
          const pageOrModalId = yModule.parent.parent.get('id');
          updateItem.tags.set('pageOrModalId', pageOrModalId);
          updateItem.add(DocumentChange.sync(getYModule, mod));
        }
      });

      const transactionOrigin = canUndo
        ? LOCAL_TRANSACTION
        : LOCAL_TRANSACTION_NOT_UNDOABLE;

      // Apply the changes to the yDoc.
      updateItem.apply(transactionOrigin);

      // If we are allowing undo, then add to undo manager.
      if (canUndo) {
        applyUndoable(updateItem);
      }
    },
    [types.UPDATE_CURRENTLY_EDITING](changes) {
      if (!sharedDoc) return;
      sharedDoc.change((doc) => {
        Object.keys(changes).forEach((componentId) => {
          doc.get('session').get('lockedComponents')
            .set(componentId, changes[componentId]);
        });
      });
    },
    [types.CLEAR_CURRENT_USER_EDITING]() {
      if (!sharedDoc) return;
      sharedDoc.change((yDoc) => {
        // find any modules currently being edited by the user
        const currentUserId = this.currentUserConnection?.id;
        const lockedComponents = yDoc.get('session').get('lockedComponents');
        lockedComponents.forEach((value, key) => {
          // if the user matches and the key is a module id (uuid), then nullify
          if (value === currentUserId && uuidValidate(key)) {
            lockedComponents.set(key, null);
          }
        });
      });
    },
    [types.SET_ADD_MODULE_DRAWER_IS_OPEN](isOpen) {
      this.addModuleDrawerIsOpen = isOpen;
    },
    async [types.ADD_MODULE]({ canvasName, payload }) {
      // generate a module
      const newModule = utils.newModuleTemplate(payload);

      // Create an undo item for the add change.
      const addItem = new DocumentChangeStackItem(sharedDoc);
      addItem.tags.set('canvasName', canvasName);

      const pageOrModalId = canvasName === 'modalModules'
        ? this.currentModalCanvasId
        : this.currentPageId;

      // Create a function to get the canvas we're adding to.
      const getCanvas = partial(utils.findCanvasByPageOrModalId, canvasName, pageOrModalId);
      const yCanvas = getCanvas(sharedDoc.document);

      if (yCanvas) {
        // Create a change to add the module that is undoable.
        addItem.add(DocumentChange.add(getCanvas, newModule));
        addItem.tags.set('pageOrModalId', yCanvas.parent?.get('id'));

        // Keep track of modules added locally.
        localAddedModulesById[newModule.id] = newModule;

        // Apply changes and allow undo.
        applyUndoable(addItem);
      }

      return newModule.id;
    },
    async [types.REPLACE_MODULE]({ canvasName, payload, replacedModuleId }) {
      // Create the new module that will replace the existing one.
      const replacementModule = utils.newModuleTemplate(payload);

      // Create an undo item for the changes.
      const replaceItem = new DocumentChangeStackItem(sharedDoc);

      replaceItem.onAdded = (manager) => {
        /**
         * Whenever we replace a module, the new module will have a different id than the existing
         * module that was replaced. When we clear undo history for a module that was a replacement
         * for another module, we also want to clear the undo history for original module that
         * was replaced. In order to do this, after we replace a module, we need to tag all the
         * stack items for the original module with the new module's id. When we clear history
         * for the replacement module, it will then clear the history for the original module as
         * well.
         */
        [...manager.undoStack, ...manager.redoStack].forEach((stackItem) => {
          /*
            If this stack item is related to the replaced module, then add a tag
            for the replacement module which will link them together.
          */
          if (stackItem.tags.get(replacedModuleId)) {
            if (!stackItem.tags.get(replacementModule.id)) {
              const replacementTags = replaceItem.tags.get(replacementModule.id);
              stackItem.tags.set(replacementModule.id, replacementTags);
            }
          }
        });
      };

      const pageOrModalId = canvasName === 'modalModules'
        ? this.currentModalCanvasId
        : this.currentPageId;

      // Create a function to get the canvas we're adding to.
      const getCanvas = partial(utils.findCanvasByPageOrModalId, canvasName, pageOrModalId);
      const yCanvas = getCanvas(sharedDoc.document);
      const yReplacedModule = utils.findYModuleById(canvasName,
        sharedDoc.document, replacedModuleId);

      if (yCanvas && yReplacedModule) {
        // Create a change to delete the replaced module.
        replaceItem.add(DocumentChange.delete(getCanvas, yReplacedModule.toJSON()));

        // Create a change to add the replacement module.
        replaceItem.add(DocumentChange.add(getCanvas, replacementModule));

        // Set tags for stack item
        replaceItem.tags.set('canvasName', canvasName);
        replaceItem.tags.set('pageOrModalId', yCanvas.parent?.get('id'));

        // Apply the changes and add to undo manager.
        applyUndoable(replaceItem);
      }

      return replacementModule.id;
    },
    [types.DELETE_MODULE]({ canvasName, id, canUndo = true }) {
      // Create undo item for delete
      const deleteItem = new DocumentChangeStackItem(sharedDoc);
      deleteItem.tags.set('canvasName', canvasName);

      const pageOrModalId = canvasName === 'modalModules'
        ? this.currentModalCanvasId
        : this.currentPageId;

      // Create a function to get the canvas we're adding to.
      const getCanvas = partial(utils.findCanvasByPageOrModalId, canvasName, pageOrModalId);
      const yCanvas = getCanvas(sharedDoc.document);
      const yModule = utils.findYModuleById(canvasName, sharedDoc.document, id);

      if (yCanvas && yModule) {
        const deleteModule = yModule.toJSON();

        // Create a change to delete the module that is undoable.
        deleteItem.add(DocumentChange.delete(getCanvas, deleteModule));
        deleteItem.tags.set('pageOrModalId', yCanvas.parent?.get('id'));

        const transactionOrigin = canUndo
          ? LOCAL_TRANSACTION
          : LOCAL_TRANSACTION_NOT_UNDOABLE;

        // Apply the changes to the yDoc.
        deleteItem.apply(transactionOrigin);

        // If we are allowing undo, then add to undo manager.
        if (canUndo) {
          applyUndoable(deleteItem);
        }
      }
    },
    [types.UPDATE_DRAFT_NAME](draftName) {
      sharedDoc.change((yDoc) => {
        yDoc.get('draft').set('name', draftName);
      }, LOCAL_TRANSACTION);
    },
    [types.UPDATE_DRAFT_DESCRIPTION](description) {
      sharedDoc.change((yDoc) => {
        yDoc.get('draft').get('options').set('description', description);
      }, LOCAL_TRANSACTION);
    },
    [types.DELETE_SECTION](section) {
      // Create an undo item for the section delete.
      const deleteSectionItem = new DocumentChangeStackItem(sharedDoc);

      // Add changes to remove pages from section.
      const yPages = utils.getPages(sharedDoc.document);
      yPages.forEach((yPage) => {
        if (yPage.get('options').get('group_id') === section.id) {
          deleteSectionItem.add(DocumentChange.update(
            utils.findYPageOptionsById,
            { group_id: undefined },
            yPage.get('id'),
          ));
        }
      });

      // Add change to delete the section.
      deleteSectionItem.add(DocumentChange.delete(utils.getSections, section));

      // Apply changes and add to undo manager.
      applyUndoable(deleteSectionItem);
    },
    [types.ADD_SECTION]({ addedSection, pageIds }) {
      // Create undo item.
      const addSectionItem = new DocumentChangeStackItem(sharedDoc);

      // Add change to add the section.
      addSectionItem.add(DocumentChange.add(utils.getSections, addedSection));

      // Add changes to add specified pages to the new section.
      pageIds.forEach((pageId) => {
        addSectionItem.add(DocumentChange.update(
          utils.findYPageOptionsById,
          { group_id: addedSection.id },
          pageId,
        ));
      });

      // Apply changes and add to undo manager.
      applyUndoable(addSectionItem);
    },
    [types.UPDATE_SECTION](section) {
      // Create undo item.
      const updateSectionItem = new DocumentChangeStackItem(sharedDoc);

      // Add change to update the section.
      updateSectionItem.add(DocumentChange.update(
        utils.getSectionById,
        section,
      ));

      // Apply changes and add to undo manager.
      applyUndoable(updateSectionItem);
    },
    [types.UPDATE_PAGE_OPTIONS]({ options, pageId }) {
      if (!sharedDoc) return;
      // If we've passed in a page id, update that page. Otherwise update
      // the current page
      const pageIdToChange = pageId || get(this, 'currentPage.id');

      sharedDoc.change((yDoc) => {
        const yPage = yjsHelper.find(yDoc.get('draft').get('pages'),
          (page) => page.get('id') === pageIdToChange);
        if (yPage) {
          yPage.set('options', yjsHelper.toY(options));
        }
      }, LOCAL_TRANSACTION);
    },
    [types.UPDATE_ALL_PAGE_OPTIONS]({ options }) {
      sharedDoc.change((yDoc) => {
        yDoc.get('draft').get('pages').forEach((yPage) => {
          const yOptions = yPage.get('options');
          const existingOptions = yOptions ? yOptions.toJSON() : {};
          yPage.set('options', yjsHelper.toY(merge(existingOptions, options)));
        });
      }, LOCAL_TRANSACTION);
    },
    async [types.COPY_PAGES](pages) {
      if (!pages.length) return;

      // Copy the pages
      const copiedPages = pages.map((p) => utils.copyPage(this.pagesSorted, p));

      // Create undo item and add pages
      const addPagesItem = new DocumentChangeStackItem(sharedDoc);
      copiedPages.forEach((copiedPage) => {
        addPagesItem.add(DocumentChange.add(utils.getPages, copiedPage));
      });

      // Apply changes and add to undo manager.
      applyUndoable(addPagesItem);

      // View the first copied page
      await this[types.VIEW_PAGE_BY_ID](first(copiedPages).id);
    },
    [types.ADD_PAGE](payload = {}) {
      const pages = this.pagesSorted;

      // start calculating the sort index for the new page
      let sortIndex = this.currentPage.sort_index;

      // at the end?
      if (this.currentPageIndex === findLastIndex(pages)) {
        sortIndex += 1;
      } else {
        // otherwise, calc the diff between the selected and its right sibling
        sortIndex = (sortIndex + pages[this.currentPageIndex + 1].sort_index) / 2;
      }

      const { page } = payload;
      if (page) {
        // make sure the page has a default height
        if (!page.options) {
          page.options = {};
        }
        if (!page.options.height) {
          page.options.height = EDITOR_CANVAS_HEIGHT;
        }

        if (!page.options.grid_disabled) {
          // set the flag, since now all pages have grid disabled
          page.options.grid_disabled = true;
        }
      }

      // Merge the default page template with any overrides
      const newPage = merge(
        utils.newPageTemplate(this.draft.options.type),
        omit(cloneDeep(page), ['id']),
        { sort_index: sortIndex },
      );

      // New pages should use the same theme as the first page in the board
      const firstPageTheme = get(this, 'draft.pages[0].options.theme');
      newPage.options = {
        ...newPage.options, // this sidesteps the issue of newPage.options sometimes being null
        group_id: get(this.currentPage, 'options.group_id'),
        theme: payload.keepTheme
          ? newPage.options.theme
          : {
            name: 'default',
            variation: 1,
            ...firstPageTheme,
            background_image: undefined,
          },
      };

      // Make sure we don't carry over old module ids
      newPage.modules = newPage.modules.map((mod) => ({
        ...mod,
        id: uuid(),
      }));
      newPage.notes = (newPage.notes || []).map((mod) => ({
        ...mod,
        id: uuid(),
      }));

      // Create add page undo item
      const addPageItem = new DocumentChangeStackItem(sharedDoc);
      addPageItem.add(DocumentChange.add(utils.getPages, newPage));

      // Apply changes and add to undo manager.
      applyUndoable(addPageItem);

      // View our new page
      this[types.VIEW_PAGE_BY_ID](newPage.id);
    },
    [types.DELETE_PAGES]({ pageIds }) {
      // Create undo item for page deletes.
      const deletePagesItem = new DocumentChangeStackItem(sharedDoc);

      // Add delete page changes to undo item.
      pageIds
        .map((pageId) => this.pagesById[pageId])
        .filter((page) => page)
        .forEach((page) => {
          deletePagesItem.add(DocumentChange.delete(utils.getPages, page));
        });

      /*
        Apply the delete page changes to the yDoc before we query below to find
        empty sections that need to be removed.
      */
      deletePagesItem.apply();

      // Get sections that have no pages.
      const emptySections = utils.getEmptySections(sharedDoc.document);

      /*
        If there are no empty sections to delete, only add the deleted pages
        to the undo history.
      */
      if (!emptySections.length) {
        applyUndoable(deletePagesItem);
        return;
      }

      // Otherwise, add changes to delete empty sections.
      emptySections.forEach((section) => {
        deletePagesItem.add(DocumentChange.delete(utils.getSections, section));
      });

      // Apply changes and add to undo manager.
      applyUndoable(deletePagesItem);
    },
    [types.UPDATE_PAGE](payload) {
      sharedDoc.change((yDoc) => {
        const yPage = utils.findYPageById(yDoc, payload.id);
        if (yPage) {
          yjsHelper.updateYMap(payload, yPage);
        }
      }, LOCAL_TRANSACTION);
    },
    [types.UPDATE_PAGES](pages) {
      // Create undo item.
      const updatePagesItem = new DocumentChangeStackItem(sharedDoc);

      // Add changes to update the pages.
      pages.forEach((page) => {
        updatePagesItem.add(DocumentChange.update(
          utils.findYPageById,
          page,
        ));
      });

      // Apply changes and add to undo manager.
      applyUndoable(updatePagesItem);
    },
    [types.UPDATE_PAGES_OPTIONS](pages) {
      // Create undo item.
      const updateOptionsItem = new DocumentChangeStackItem(sharedDoc);

      // Add changes to update the page options.
      pages.forEach((page) => {
        updateOptionsItem.add(DocumentChange.update(
          utils.findYPageOptionsById,
          page.options,
          page.id,
        ));
      });

      // Apply changes and add to undo manager.
      applyUndoable(updateOptionsItem);
    },
    [types.MOVE_PAGES]({ pages }) {
      // Create undo item for the page moves.
      const movePagesItem = new DocumentChangeStackItem(sharedDoc);

      // Add changes to update the pages.
      pages.forEach((page) => {
        // Change to update the page's sort order.
        if (isNumber(page.sort_index)) {
          movePagesItem.add(DocumentChange.update(
            utils.findYPageById,
            { sort_index: page.sort_index },
            page.id,
          ));
        }

        // Change to update the page's section.
        movePagesItem.add(DocumentChange.update(
          utils.findYPageOptionsById,
          { group_id: page.options?.group_id },
          page.id,
        ));
      });

      /*
        Apply the page updates to the yDoc first before querying below to find
        the sections that are now empty.
      */
      movePagesItem.apply();

      // Get sections that are empty after moving the pages.
      const emptySections = utils.getEmptySections(sharedDoc.document);

      /*
        If there are no empty sections to delete, only add the moved pages
        to the undo history.
      */
      if (!emptySections.length) {
        applyUndoable(movePagesItem);
        return;
      }

      // Otherwise, delete empty sections.
      emptySections.forEach((section) => {
        movePagesItem.add(DocumentChange.delete(utils.getSections, section));
      });

      // Apply changes and add to undo manager.
      applyUndoable(movePagesItem);
    },
    [types.UPDATE_CHAT_ENABLED](chatEnabled) {
      sharedDoc.change((doc) => {
        doc.get('draft').get('options').set('chat_enabled', chatEnabled);
      }, LOCAL_TRANSACTION);
    },
    /**
     * publish a draft
     * payload must contain draftId param
     */
    async [types.PUBLISH_DRAFT]({ draftId }) {
      const appStore = useAppStore();
      // grab the id
      const creation = {
        id: draftId,
      };

      // set loading
      appStore[types.UPDATE_LOADING](true);

      const result = await this.api.post('/creations/', creation,
        {
          headers: {
            'X-Token': appStore.apiToken,
          },
        });

      // Update the store with the new draft
      if (result.status === 201) {
        this.publishedCreationId = result.data.creation.id;
        appStore[types.UPDATE_LOADING](false);

        const publishedOn = get(result, 'data.creation.metadata.updated_on');
        if (publishedOn && sharedDoc) {
          sharedDoc.change((yDoc) => {
            yDoc.get('draft').get('metadata').set('published_on', publishedOn);
          }, LOCAL_TRANSACTION);
        }
      } else {
        appStore[types.UPDATE_LOADING](false);
        // Error handling
        throw result;
      }

      return get(result, 'data.creation');
    },
    [types.SET_DRAFT_IS_DIRTY](isDirty) {
      if (!sharedDoc) return;
      sharedDoc.change((yDoc) => {
        yDoc.get('session').set('draftIsDirty', isDirty);
      }, LOCAL_TRANSACTION);
    },
    [types.COMMIT_UPDATE_PAGE](payload) {
      const idx = this.draft.pages.findIndex((page) => page.id === payload.id);
      if (idx === -1) return; // prevent editing of pages that don't exist
      this.draft.pages[idx] = payload;
    },
    [types.APPLY_UPDATE_PAGE]({ change, transaction }) {
      const assetStore = useAssetStore();
      // Get the page
      const newPage = utils.isUpdatePageOptions(change)
        ? change.target.parent.toJSON()
        : change.target.toJSON();
      const existingPage = this.draft.pages.find((page) => page.id === newPage.id);

      // Update state
      this[types.COMMIT_UPDATE_PAGE](newPage);

      const newBackgroundImage = get(newPage, 'options.theme.background_image');
      const existingBackgroundImage = get(existingPage, 'options.theme.background_image');
      const newPageAssetId = get(newPage, 'options.asset_id');
      const existingPageAssetId = get(existingPage, 'options.asset_id');

      // Update the asset cache if the background image id has changed
      if (newBackgroundImage && existingBackgroundImage !== newBackgroundImage) {
        assetStore[types.CACHE_ASSET](newBackgroundImage);
      }

      // Update the asset cache if the page asset_id has changed
      if (newPageAssetId && existingPageAssetId !== newPageAssetId) {
        assetStore[types.CACHE_ASSET](newPageAssetId);
      }

      if (sharedDoc.isHost) {
        this[types.ENQUEUE_PAGE_PATCH]({ page: newPage, action: 'update' });
      }

      /*
        If the page was updated by another user, then:
        1. Remove any updates to the same properties from this user's
        undo history for this page. This is to prevent this user from
        undoing another user's more recent change to a property value.
        2. Remove any add or delete undo items for this page from
        this user's undo history.
      */
      if (!transactionIsLocal(transaction)) {
        const updatedKeys = [...change.changes?.keys?.entries()];
        this[types.REMOVE_FROM_UNDO_HISTORY]((item) => {
          const tags = item.tags.get(newPage.id);
          if (!tags) return false;
          return tags.has('add')
            || tags.has('delete')
            || updatedKeys.some((key) => tags.has('update') && tags.has(first(key)));
        });
      }
    },
    [types.CHECK_MODULE_Z_POSITIONS](changes) {
      // Extract all the pages that were updated with these changes.
      const pageIds = changes.reduce((pageIdSet, change) => {
        if (utils.isUpdateModule(change) && change.path[2] === 'modules') {
          const updatedPageId = change.target.parent.parent.get('id');
          if (updatedPageId) pageIdSet.add(updatedPageId);
        } else if (utils.isAddModule(change) && change.path[2] === 'modules') {
          const pageId = change.target.parent.get('id');
          if (pageId) pageIdSet.add(pageId);
        } else if (utils.isAddPage(change)) {
          chain([...change.changes.added.values()])
            .map((added) => added.content.type.get('id'))
            .uniq()
            .value()
            .forEach((newPageId) => {
              pageIdSet.add(newPageId);
            });
        } else if (utils.isUpdatePage(change)) {
          const updatedPageId = change.target.get('id');
          if (updatedPageId) pageIdSet.add(updatedPageId);
        }
        return pageIdSet;
      }, new Set());

      if (!pageIds.size) {
        return;
      }

      sharedDoc.change((yDoc) => {
        const yPagesById = {};
        yDoc.get('draft').get('pages').forEach((yPage) => {
          yPagesById[yPage.get('id')] = yPage;
        });

        pageIds.forEach((pageId) => {
          const page = yPagesById[pageId];
          if (!page) return;
          const gridDisabled = page.get('options') && page.get('options').get('grid_disabled');
          if (!gridDisabled) return;
          const modules = page.get('modules');
          if (!modules) return;
          const yModulesById = {};
          modules.forEach((yModule) => {
            yModulesById[yModule.get('id')] = yModule;
          });

          const modulesSorted = this.getModulesByPageIdOrderedByZPosition(pageId);
          const maxModIndex = modulesSorted.length - 1;

          // Loop through the modules and look for consecutive modules with the same z_position.
          for (let modIndex = 0; modIndex <= maxModIndex;) {
            const currentZPosition = modulesSorted[modIndex].layout.z_position;
            let nextModIndex = modIndex + 1;

            const getNextZPosition = () => (nextModIndex <= maxModIndex
              ? modulesSorted[nextModIndex].layout.z_position
              : modulesSorted[maxModIndex].layout.z_position + 1);
            let nextZPosition = getNextZPosition();

            while (nextZPosition === currentZPosition) {
              nextModIndex += 1;
              nextZPosition = getNextZPosition();
            }

            const modulesWithSameZPosition = modulesSorted.slice(modIndex, nextModIndex);

            /*
              If modules with the same z_position are found, calculate unique z_positions for each
              while maintaining z_position order.
            */
            if (modulesWithSameZPosition.length > 1) {
              const previousZPosition = modIndex === 0
                ? yModulesById[modulesSorted[modIndex].id].get('layout').get('z_position') - 1
                : yModulesById[modulesSorted[modIndex - 1].id].get('layout').get('z_position');
              const fractionalIncrement = (nextZPosition - previousZPosition)
                / (modulesWithSameZPosition.length + 1);

              modulesWithSameZPosition.forEach((mod, index) => {
                const yModule = yModulesById[mod.id];
                if (yModule) {
                  const increment = (index + 1) * fractionalIncrement;
                  const layoutJson = yModule.get('layout').toJSON();
                  layoutJson.z_position = previousZPosition + increment;
                  /*
                    Update the entire layout object to ensure 'APPLY_UPDATE_MODULE' handlers
                    are called after the update.
                  */
                  yModule.set('layout', yjsHelper.toY(layoutJson));
                }
              });
            }

            modIndex = nextModIndex;
          }
        });
      }, LOCAL_TRANSACTION);
    },
    [types.CHECK_SORT_INDEXES]() {
      const { pagesSorted } = this;
      if (!pagesSorted) return;

      sharedDoc.change((yDoc) => {
        const yPagesById = {};
        yDoc.get('draft').get('pages').forEach((yPage) => {
          yPagesById[yPage.get('id')] = yPage;
        });

        const maxPageIndex = pagesSorted.length - 1;
        for (let pageIndex = 0; pageIndex <= maxPageIndex;) {
          const currentSortIndex = pagesSorted[pageIndex].sort_index;
          let nextPageIndex = pageIndex + 1;

          const getNextSortIndex = () => (nextPageIndex <= maxPageIndex
            ? pagesSorted[nextPageIndex].sort_index
            : pagesSorted[maxPageIndex].sort_index + 1);
          let nextSortIndex = getNextSortIndex();

          while (nextSortIndex === currentSortIndex) {
            nextPageIndex += 1;
            nextSortIndex = getNextSortIndex();
          }

          const pagesWithSameSortIndex = pagesSorted.slice(pageIndex, nextPageIndex);

          if (pagesWithSameSortIndex.length > 1) {
            const previousSortIndex = pageIndex === 0
              ? pagesSorted[pageIndex].sort_index - 1
              : yPagesById[pagesSorted[pageIndex - 1].id].get('sort_index');
            const fractionalIncrement = (nextSortIndex - previousSortIndex)
              / (pagesWithSameSortIndex.length + 1);

            pagesWithSameSortIndex.forEach((page, index) => {
              const yPage = yPagesById[page.id];
              if (yPage) {
                const increment = (index + 1) * fractionalIncrement;
                yPage.set('sort_index', previousSortIndex + increment);
              }
            });
          }

          pageIndex = nextPageIndex;
        }
      }, LOCAL_TRANSACTION);
    },
    [types.COMMIT_DELETE_PAGE](pageId) {
      if (this.draft.pages.length === 1) return; // prevent deleting the only page

      const idx = this.draft.pages.findIndex((page) => page.id === pageId);
      if (idx === -1) return; // prevent deletion of pages that don't exist

      // Get the page's position in the array or sorted pages.
      const deletedPageSortedIndex = this.pagesSorted
        .findIndex((page) => page.id === pageId);

      this.draft.pages.splice(idx, 1);

      // If the current page was deleted, reset to closest page.
      if (this.currentPageId === pageId) {
        const maxPagesSortedIndex = this.pagesSorted.length - 1;
        const currentPageId = deletedPageSortedIndex > maxPagesSortedIndex
          ? this.pagesSorted[maxPagesSortedIndex].id
          : this.pagesSorted[deletedPageSortedIndex].id;
        this.currentPageId = currentPageId;
      }
    },
    [types.APPLY_DELETE_PAGE]({ change, transaction }) {
      // get the page
      chain([...change.changes.deleted.values()])
        // eslint-disable-next-line no-underscore-dangle
        .map((deleted) => deleted.content.type._map.get('id').content.arr[0])
        .uniq()
        .value()
        .forEach((pageId) => {
          // Update state
          this[types.COMMIT_DELETE_PAGE](pageId);

          if (sharedDoc.isHost) {
            this[types.ENQUEUE_PAGE_PATCH]({ page: { id: pageId }, action: 'remove' });
          }

          /*
            If the page was deleted by another user, then remove any undo items related
            to this page from this user's undo history.
          */
          if (!transactionIsLocal(transaction)) {
            // Remove history for updates to this page.
            this[types.REMOVE_FROM_UNDO_HISTORY]((item) => (
              item.tags.get(pageId) || item.tags.get('pageOrModalId') === pageId
            ));
          }
        });
    },
    [types.APPLY_ADD_PAGE]({ change, yDoc }) {
      const assetStore = useAssetStore();
      // get the page
      chain([...change.changes.added.values()])
        .map((added) => added.content.type.get('id'))
        .uniq()
        .value()
        .forEach((newPageId) => {
          const pages = yDoc.get('draft').get('pages');
          const page = yjsHelper.find(pages, (yPage) => yPage.get('id') === newPageId);
          if (page) {
            const newPage = page.toJSON();

            // Update state
            this.draft.pages.push(newPage);

            // Update the asset cache if the new slide is a full-slide asset
            // eslint-disable-next-line
            if (newPage.options?.asset_id) {
              assetStore[types.CACHE_ASSET](newPage.options.asset_id);
            }

            if (sharedDoc.isHost) {
              this[types.ENQUEUE_PAGE_PATCH]({ page: newPage, action: 'add' });
            }
          }
        });
    },
    [types.COMMIT_UPDATE_MODULE](payload) {
      const {
        pageIndex,
        canvasName,
        moduleIndex,
        newModule,
      } = payload;

      this.draft.pages[pageIndex][canvasName][moduleIndex] = newModule;
    },
    [types.APPLY_UPDATE_MODULE]({ change, transaction }) {
      const assetStore = useAssetStore();
      const trackingStore = useTrackingStore();
      const uploadStore = useUploadStore();
      const updatedModule = change.target.toJSON();

      // find the page by module id
      const pageIndex = utils.getPageIndexByModuleId(this.draft, updatedModule.id);
      if (pageIndex === -1) return;
      const canvasName = change.path[2];
      const page = this.draft.pages[pageIndex];
      const moduleIndex = page[canvasName]
        .findIndex((mod) => mod.id === updatedModule.id);
      if (moduleIndex === -1) return;

      const existingModule = page[canvasName][moduleIndex];

      // If we've swapped assets, update the asset cache
      if (['asset', 'upload'].includes(existingModule.type)
        && existingModule.options.asset_id !== updatedModule.options.asset_id) {
        assetStore[types.CACHE_ASSET](updatedModule.options.asset_id);
        if (transaction.origin === LOCAL_TRANSACTION) {
          trackingStore[types.SEND_CLICKLOG_EVENT]({
            ids: [updatedModule.options.asset_id],
          });
        }
      } else if (existingModule.type === 'upload' && existingModule.options.upload_id !== updatedModule.options.upload_id) {
        uploadStore[types.CACHE_UPLOAD](updatedModule.options.upload_id);
      }

      const moduleResized = existingModule.layout.width !== updatedModule.layout.width
        || existingModule.layout.height !== updatedModule.layout.height;
      const moduleMoved = existingModule.layout.x_position !== updatedModule.layout.x_position
        || existingModule.layout.y_position !== updatedModule.layout.y_position;

      // Update state
      this[types.COMMIT_UPDATE_MODULE]({
        canvasName,
        moduleIndex,
        newModule: updatedModule,
        pageIndex,
      });

      if (moduleResized) bus.emit('resized', updatedModule.id);
      if (moduleMoved) bus.emit('moved', updatedModule.id);

      if (sharedDoc.isHost) {
        this[types.ENQUEUE_PAGE_PATCH]({ page, action: 'update' });
      }

      /*
        If this module update came from another connected user, then remove all undo
        history for this module for the current user.
      */
      if (!transactionIsLocal(transaction)) {
        this[types.REMOVE_FROM_UNDO_HISTORY]((item) => item.tags.get(updatedModule.id));
      }

      // Broadcast the updated module
      nextTick(() => {
        bus.emit('module:updated', {
          updatedModule,
          isLocalUndoRedo: transaction.origin === LOCAL_TRANSACTION_UNDO_MANAGER,
          isLocal: transactionIsLocal(transaction),
          isLocalNotUndoable: transaction.origin === LOCAL_TRANSACTION_NOT_UNDOABLE,
        });
      });
    },
    [types.COMMIT_UPDATE_MODAL_MODULE](payload) {
      const {
        modalIndex,
        moduleIndex,
        newModule,
      } = payload;

      this.draft.modals[modalIndex].modules[moduleIndex] = newModule;
    },
    [types.APPLY_UPDATE_MODAL_MODULE]({ change, transaction }) {
      const assetStore = useAssetStore();
      const trackingStore = useTrackingStore();
      const uploadStore = useUploadStore();
      const updatedModule = change.target.toJSON();

      // find the page by module id
      const modalIndex = utils.getModalIndexByModuleId(this.draft, updatedModule.id);
      if (modalIndex === -1) return;
      const modal = this.draft.modals[modalIndex];
      const moduleIndex = modal.modules
        .findIndex((mod) => mod.id === updatedModule.id);
      if (moduleIndex === -1) return;

      const existingModule = modal.modules[moduleIndex];

      // If we've swapped assets, update the asset cache
      if (['asset', 'upload'].includes(existingModule.type)
        && existingModule.options.asset_id !== updatedModule.options.asset_id) {
        assetStore[types.CACHE_ASSET](updatedModule.options.asset_id);
        if (transaction.origin === LOCAL_TRANSACTION) {
          trackingStore[types.SEND_CLICKLOG_EVENT]({
            ids: [updatedModule.options.asset_id],
          });
        }
      } else if (existingModule.type === 'upload' && existingModule.options.upload_id !== updatedModule.options.upload_id) {
        uploadStore[types.CACHE_UPLOAD](updatedModule.options.upload_id);
      }

      const moduleResized = existingModule.layout.width !== updatedModule.layout.width
        || existingModule.layout.height !== updatedModule.layout.height;
      const moduleMoved = existingModule.layout.x_position !== updatedModule.layout.x_position
        || existingModule.layout.y_position !== updatedModule.layout.y_position;

      // Update state
      this[types.COMMIT_UPDATE_MODAL_MODULE]({
        moduleIndex,
        newModule: updatedModule,
        modalIndex,
      });

      if (moduleResized) bus.emit('resized', updatedModule.id);
      if (moduleMoved) bus.emit('moved', updatedModule.id);

      if (sharedDoc.isHost) {
        this[types.ENQUEUE_MODAL_PATCH]({ modal, action: 'update' });
      }

      /*
        If this module update came from another connected user, then remove all undo
        history for this module for the current user.
      */
      if (!transactionIsLocal(transaction)) {
        this[types.REMOVE_FROM_UNDO_HISTORY]((item) => item.tags.get(updatedModule.id));
      }

      // Broadcast the updated module.
      nextTick(() => {
        bus.emit('module:updated', {
          updatedModule,
          isLocalUndoRedo: transaction.origin === LOCAL_TRANSACTION_UNDO_MANAGER,
          isLocal: transactionIsLocal(transaction),
          isLocalNotUndoable: transaction.origin === LOCAL_TRANSACTION_NOT_UNDOABLE,
        });
      });
    },
    [types.COMMIT_ADD_MODULE](payload) {
      payload.page[payload.canvasName].push(payload.newModule);
    },
    [types.APPLY_ADD_MODULE]({ change, yDoc, transaction }) {
      const assetStore = useAssetStore();
      const trackingStore = useTrackingStore();
      const uploadStore = useUploadStore();
      const pageId = change.target.parent.get('id');
      const yDocPageIndex = yjsHelper.findIndex(yDoc.get('draft').get('pages'),
        (page) => page.get('id') === pageId);
      if (yDocPageIndex === -1) return;
      const canvasName = change.path[2];
      const yModules = yDoc.get('draft').get('pages').get(yDocPageIndex).get(canvasName);

      chain([...change.changes.added.entries()])
        .map((added) => map(added, (item) => item.content.type.get('id')))
        .flatten()
        .uniq()
        .value()
        .forEach((newModuleId) => {
          const yNewModule = yjsHelper.find(yModules, (yMod) => yMod.get('id') === newModuleId);
          if (yNewModule) {
            const newModule = yNewModule.toJSON();

            const pageToUpdate = this.draft.pages.find((page) => page.id === pageId);
            if (!pageToUpdate) return;

            const moduleExists = pageToUpdate[canvasName].find((mod) => mod.id === newModule.id);
            if (moduleExists) return;

            this[types.COMMIT_ADD_MODULE]({ page: pageToUpdate, newModule, canvasName });

            // Cache grid matrix for current page updates to modules canvas only
            if (pageToUpdate.id === this.currentPage.id && canvasName === 'modules') {
              this[types.CACHE_GRID_MATRIX]({ columns: 12 });
            }

            // Cache our new asset or upload if necessary
            if (['asset', 'upload'].includes(newModule.type) && newModule.options.asset_id) {
              assetStore[types.CACHE_ASSET](newModule.options.asset_id);
              if (transaction.origin === LOCAL_TRANSACTION) {
                trackingStore[types.SEND_CLICKLOG_EVENT]({
                  ids: [newModule.options.asset_id],
                });
              }
            } else if (newModule.type === 'upload' && newModule.options.upload_id) {
              uploadStore[types.CACHE_UPLOAD](newModule.options.upload_id);
            }

            // update page
            if (sharedDoc.isHost) {
              this[types.ENQUEUE_PAGE_PATCH]({ page: pageToUpdate, action: 'update' });
            }

            /*
              Defer this event to ensure that any modules added have been rendered
              and can listen for this event.
            */
            nextTick(() => {
              bus.emit('module:added', {
                id: newModule.id,
                canvasName,
                page: pageToUpdate,
                isLocal: transactionIsLocal(transaction),
              });
            });
          }
        });
    },
    [types.COMMIT_ADD_MODAL_MODULE](payload) {
      payload.modal.modules.push(payload.newModule);
    },
    [types.APPLY_ADD_MODAL_MODULE]({ change, yDoc, transaction }) {
      const assetStore = useAssetStore();
      const trackingStore = useTrackingStore();
      const uploadStore = useUploadStore();
      const modalId = change.target.parent.get('id');
      const yDocModalIndex = yjsHelper.findIndex(yDoc.get('draft').get('modals'),
        (modal) => modal.get('id') === modalId);
      if (yDocModalIndex === -1) return;
      const yModules = yDoc.get('draft').get('modals').get(yDocModalIndex).get('modules');

      chain([...change.changes.added.entries()])
        .map((added) => map(added, (item) => item.content.type.get('id')))
        .flatten()
        .uniq()
        .value()
        .forEach((newModuleId) => {
          const yNewModule = yjsHelper.find(yModules, (yMod) => yMod.get('id') === newModuleId);
          if (yNewModule) {
            const newModule = yNewModule.toJSON();

            const modalToUpdate = this.draft.modals.find((modal) => modal.id === modalId);
            if (!modalToUpdate) return;

            const moduleExists = modalToUpdate.modules.find((mod) => mod.id === newModule.id);
            if (moduleExists) return;

            this[types.COMMIT_ADD_MODAL_MODULE]({ modal: modalToUpdate, newModule });

            // Cache our new asset or upload if necessary
            if (['asset', 'upload'].includes(newModule.type) && newModule.options.asset_id) {
              assetStore[types.CACHE_ASSET](newModule.options.asset_id);
              if (transaction.origin === LOCAL_TRANSACTION) {
                trackingStore[types.SEND_CLICKLOG_EVENT]({
                  ids: [newModule.options.asset_id],
                });
              }
            } else if (newModule.type === 'upload' && newModule.options.upload_id) {
              uploadStore[types.CACHE_UPLOAD](newModule.options.upload_id);
            }

            // update modal
            if (sharedDoc.isHost) {
              this[types.ENQUEUE_MODAL_PATCH]({ modal: modalToUpdate, action: 'update' });
            }

            /*
              Defer this event to ensure that any modules added have been rendered
              and can listen for this event.
            */
            nextTick(() => {
              bus.emit('module:added', {
                id: newModule.id,
                canvasName: 'modalModules',
                modal: modalToUpdate,
                isLocal: transactionIsLocal(transaction),
              });
            });
          }
        });
    },
    [types.APPLY_DELETE_MODULE]({ change, yDoc, transaction }) {
      const pageId = change.target.parent.get('id');
      const yPage = yjsHelper.find(yDoc.get('draft').get('pages'), (page) => page.get('id') === pageId);
      if (yPage) {
        const updatedPage = yPage.toJSON();
        const pageIndex = this.draft.pages.findIndex((page) => page.id === updatedPage.id);
        if (pageIndex !== -1) {
          this[types.COMMIT_UPDATE_PAGE](updatedPage);

          if (sharedDoc.isHost) {
            this[types.ENQUEUE_PAGE_PATCH]({ page: updatedPage, action: 'update' });
          }
        }
      }

      [...change.changes.deleted.entries()].forEach((entry) => {
        const yModule = get(first(entry), 'content.type._map');
        if (yModule) {
          const moduleId = get(yModule.get('id'), 'content.arr[0]');
          if (moduleId) {
            if (transactionIsLocal(transaction)) {
              // Mark the module as not being locked in the shared document.
              this[types.UPDATE_CURRENTLY_EDITING]({ [moduleId]: null });
            }
            if (!transactionIsLocal(transaction)
              || transaction.origin === LOCAL_TRANSACTION_NOT_UNDOABLE) {
              /*
                Whenever a module is deleted by a remote user or the module was deleted
                locally but is not undoable (e.g. grid conflict resolution), then remove all
                undo history for the module for the current user.
              */
              this[types.REMOVE_FROM_UNDO_HISTORY]((item) => item.tags.get(moduleId));
            }
          }
        }
      });
    },
    [types.COMMIT_UPDATE_MODAL](payload) {
      const idx = this.draft.modals.findIndex((modal) => modal.id === payload.id);
      if (idx === -1) return; // prevent editing of modals that don't exist

      this.draft.modals[idx] = payload;
    },
    [types.APPLY_DELETE_MODAL_MODULE]({ change, yDoc, transaction }) {
      const modalId = change.target.parent.get('id');
      const yModal = yjsHelper.find(yDoc.get('draft').get('modals'), (modal) => modal.get('id') === modalId);
      if (yModal) {
        const updatedModal = yModal.toJSON();
        const modalIndex = this.draft.modals.findIndex((modal) => modal.id === updatedModal.id);
        if (modalIndex !== -1) {
          this[types.COMMIT_UPDATE_MODAL](updatedModal);

          if (sharedDoc.isHost) {
            this[types.ENQUEUE_MODAL_PATCH]({ modal: updatedModal, action: 'update' });
          }
        }
      }

      [...change.changes.deleted.entries()].forEach((entry) => {
        const yModule = get(first(entry), 'content.type._map');
        if (yModule) {
          const moduleId = get(yModule.get('id'), 'content.arr[0]');
          if (moduleId) {
            if (transactionIsLocal(transaction)) {
              // Mark the module as not being locked in the shared document.
              this[types.UPDATE_CURRENTLY_EDITING]({ [moduleId]: null });
            }
            if (!transactionIsLocal(transaction)
              || transaction.origin === LOCAL_TRANSACTION_NOT_UNDOABLE) {
              /*
                Whenever a module is deleted by a remote user or the module was deleted
                locally but is not undoable (e.g. grid conflict resolution), then remove all
                undo history for the module for the current user.
              */
              this[types.REMOVE_FROM_UNDO_HISTORY]((item) => item.tags.get(moduleId));
            }
          }
        }
      });
    },
    [types.APPLY_UPDATE_DRAFT]({ change, yDoc }) {
      chain([...change.changes.keys.entries()])
        .map((entry) => first(entry))
        .uniq()
        .value()
        .forEach((property) => {
          if (property === 'name') {
            const draftName = yDoc.get('draft').get('name');
            this.draft.name = draftName;
            if (sharedDoc.isHost) {
              queue.add({
                op: 'replace',
                path: '/name',
                value: draftName,
              });
            }
          } else if (property === 'shares') {
            const yShares = yDoc.get('draft').get('shares');
            if (yShares) {
              this.draft.shares = yShares.toJSON();
            }
          }
        });
    },
    [types.UPDATE_SHARES](shares) {
      if (get(sharedDoc, 'isConnected')) {
        sharedDoc.change((yDoc) => {
          yDoc.get('draft').set('shares', yjsHelper.toY(shares));
        }, LOCAL_TRANSACTION);
      } else {
        this.draft.shares = shares;
      }
    },
    [types.UPDATE_SHARE_ID](shareId) {
      if (get(sharedDoc, 'isConnected')) {
        sharedDoc.change((yDoc) => {
          yDoc.get('draft').set('share_id', shareId);
        }, LOCAL_TRANSACTION);
      } else {
        this.draft.share_id = shareId;
      }
    },
    [types.UPDATE_SAVING](saving) {
      // This is only its own method so that it's easier to watch in tests
      this.saving = saving;
    },
    [types.APPLY_UPDATE_DRAFT_OPTIONS]({ change, yDoc }) {
      const chatStore = useChatStore();

      chain([...change.changes.keys.entries()])
        .map((entry) => first(entry))
        .uniq()
        .value()
        .forEach((property) => {
          if (['description', 'chat_enabled'].includes(property)) {
            const propertyValue = yDoc.get('draft').get('options').get(property);
            if (property === 'description') {
              this.draft.options.description = propertyValue;
              if (sharedDoc.isHost) {
                throttlePatchDraftDescription({
                  op: 'add',
                  path: `/options/${property}`,
                  value: propertyValue,
                });
              }
            } else if (property === 'chat_enabled') {
              this.draft.options.chat_enabled = propertyValue;
              if (sharedDoc.isHost) {
                queue.add({
                  op: 'add',
                  path: `/options/${property}`,
                  value: propertyValue,
                });
              }

              // Ensure chat module toggle is kept in sync.
              chatStore[types.SET_CHAT_TOGGLE_ENABLED](propertyValue);
              if (propertyValue) {
                // If the chat is being turned on, we have no shared document or
                // session and need to initialize one
                chatStore[types.GET_CHAT]({ draft: this.draft });
              } else {
                // If the chat is being turned off, we can clear out the chat session
                chatStore[types.CLOSE_EDITING_SESSION](null);
              }
            }
          }
        });
    },
    [types.COMMIT_ADD_SECTION](payload) {
      if (!this.draft?.options?.groups) return;
      this.draft.options.groups.push(payload);
    },
    [types.COMMIT_DELETE_SECTION](sectionId) {
      if (!this.draft?.options?.groups) return;
      const sectionIdx = this.draft.options.groups
        .findIndex((section) => section.id === sectionId);
      if (sectionIdx === -1) return;
      this.draft.options.groups.splice(sectionIdx, 1);
    },
    [types.COMMIT_UPDATE_SECTION](payload) {
      if (!this.draft?.options?.groups) return;
      const sectionIdx = this.draft.options.groups
        .findIndex((section) => section.id === payload.id);
      if (sectionIdx === -1) return;
      this.draft.options.groups[sectionIdx] = payload;
    },
    [types.APPLY_UPDATE_SECTION]({ change, yDoc, transaction }) {
      // Apply added sections
      chain([...change.changes.added.values()])
        .map((added) => added.content.type.get('id'))
        .uniq()
        .value()
        .forEach((sectionId) => {
          const ySections = utils.getSections(yDoc);
          const section = yjsHelper.find(ySections, (ySection) => ySection.get('id') === sectionId);

          if (section) {
            const newSection = section.toJSON();

            this[types.COMMIT_ADD_SECTION](newSection);
            if (sharedDoc.isHost) {
              queue.add({
                op: 'add',
                path: '/options/groups/-',
                value: newSection,
              });
            }
          }
        });

      // Apply deleted sections
      chain([...change.changes.deleted.values()])
        // eslint-disable-next-line no-underscore-dangle
        .map((deleted) => deleted.content.type._map.get('id').content.arr[0])
        .uniq()
        .value()
        .forEach((sectionId) => {
          if (sharedDoc.isHost) {
            const sectionIdx = this.draft.options.groups
              .findIndex((section) => section.id === sectionId);

            if (sectionIdx === -1) return;

            queue.add({
              op: 'remove',
              path: `/options/groups/${sectionIdx}`,
            });
          }
          this[types.COMMIT_DELETE_SECTION](sectionId);

          /*
            If the section was deleted by another user, then remove any undo items related
            to this section from this user's undo history.
          */
          if (!transactionIsLocal(transaction)) {
            this[types.REMOVE_FROM_UNDO_HISTORY]((item) => item.tags.get(sectionId));
          }
        });

      // Apply updated sections
      const updatedKeys = [...change.changes.keys.entries()];
      if (updatedKeys.length) {
        const sectionIdx = last(change.path);
        const ySection = utils.getSections(yDoc).get(sectionIdx);
        if (!ySection) return;

        const newSection = ySection.toJSON();

        if (sharedDoc.isHost) {
          queue.add({
            op: 'replace',
            path: `/options/groups/${newSection.id}`,
            value: newSection,
          });
        }
        this[types.COMMIT_UPDATE_SECTION](newSection);

        /*
          If the section was updated by another user, then:
          1. Remove any updates to the same properties from this user's
          undo history for this section. This is to prevent this user from
          undoing another user's more recent change to a property value.
          2. Remove any add or delete undo items for this section from
          this user's undo history.
        */
        if (!transactionIsLocal(transaction)) {
          this[types.REMOVE_FROM_UNDO_HISTORY]((item) => {
            const tags = item.tags.get(newSection.id);
            if (!tags) return false;
            return tags.has('add')
              || tags.has('delete')
              || updatedKeys.some((key) => tags.has('update') && tags.has(first(key)));
          });
        }
      }
    },
    [types.APPLY_UPDATE_DRAFT_METADATA]({ change, yDoc }) {
      chain([...change.changes.keys.entries()])
        .map((entry) => first(entry))
        .uniq()
        .value()
        .forEach((property) => {
          if (property === 'published_on') {
            const publishedOn = yDoc.get('draft').get('metadata').get('published_on');
            this.draft.metadata.published_on = publishedOn;
          }
        });
    },
    [types.SHOW_ADD_MODULE_CONFLICT_MESSAGES]() {
      sharedDoc.change((yDoc) => {
        yDoc.get('messages').get('addModuleConflictMessages').forEach((yMessage) => {
          if (yMessage.get('moduleId') in localAddedModulesById && !yMessage.get('displayed')) {
            bus.emit('invalidPlacementAttempted');
            yMessage.set('displayed', true);
          }
        });
      }, LOCAL_TRANSACTION);
    },
    [types.ENQUEUE_PAGE_PATCH]({ page, action }) {
      if (action === 'update') {
        queue.add({
          op: 'replace',
          path: `/pages/${page.id}`,
          value: page,
        });
      } else if (action === 'remove') {
        queue.add({
          op: 'remove',
          path: `/pages/${page.id}`,
        });
      } else if (action === 'add') {
        queue.add({
          op: 'add',
          path: '/pages/-',
          value: page,
        });
      }
    },
    [types.ENQUEUE_MODAL_PATCH]({ modal, action }) {
      if (action === 'update') {
        queue.add({
          op: 'replace',
          path: `/modals/${modal.id}`,
          value: modal,
        });
      } else if (action === 'remove') {
        queue.add({
          op: 'remove',
          path: `/modals/${modal.id}`,
        });
      } else if (action === 'add') {
        queue.add({
          op: 'add',
          path: '/modals/-',
          value: modal,
        });
      }
    },
    [types.ENQUEUE_DRAFT_PATCH](draft) {
      ['name', 'pages'].forEach((key) => {
        queue.add({
          op: 'replace',
          path: `/${key}`,
          value: draft[key],
        });
      });
      if ('description' in draft.options) {
        queue.add({
          op: 'add',
          path: '/options/description',
          value: get(draft, 'options.description'),
        });
      }
      if ('chat_enabled' in draft.options) {
        queue.add({
          op: 'add',
          path: '/options/chat_enabled',
          value: get(draft, 'options.chat_enabled'),
        });
      }
    },
    async [types.OPEN_EDITING_SESSION](payload) {
      const appStore = useAppStore();
      const errorStore = useErrorStore();
      const userStore = useUserStore();
      try {
        appStore[types.UPDATE_LOADING](true);
        this[types.CLOSE_EDITOR_DRAWERS]();
        this[types.CLOSE_EDITING_SESSION]();
        const {
          options: draftOptions,
          id,
          references,
          is_deleted: isDeleted,
          metadata: { published_on: publishedOn } = {},
        } = await this[types.GET_DRAFT_REFERENCES](payload);

        /*
          If the draft is deleted, don't open the editing session and
          throw an error instead.
        */
        if (isDeleted) {
          throw new DraftIsDeletedError(
            this.draftPointerAssetId({ references }),
            id,
            !!publishedOn,
          );
        }

        const { draftId, pageId } = payload;

        const draftPointerAssetReference = references
          .find((ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'head');

        if (draftPointerAssetReference && draftId !== draftPointerAssetReference.reference_id) {
          // If we've landed on a draft using the mongo id, redirect to the same route with
          // the pointer asset id instead
          window.location.href = window.location.href.replace(
            `id=${draftId}`,
            `id=${draftPointerAssetReference.reference_id}`,
          );
          return;
        }

        sharedDoc = new SharedDocument({
          appId: 'studio',
          env: this.env,
          region: this.region,
          resourceId: id,
          references,
          baseUrl: this.api.defaults.baseURL,
          userData: {
            firstName: userStore.user.first_name,
            lastName: userStore.user.last_name,
          },
          getDocument: () => this[types.GET_SHARED_DOCUMENT](payload),
          userToken: appStore.apiToken,
          axiosClient: this.deApi,
        });

        sharedDoc.on('connected', (yDoc) => {
          const jsDoc = yDoc.toJSON();
          const { draft } = jsDoc;

          if (draft.options.is_template && draft.options.template_type === 'activity'
            && !userStore.isDeUser) {
            // Non-editorial users should not be able to edit activity templates
            errorStore[types.SET_ERROR]({
              active: true,
              error: {
                component: 'ActivityTemplatePermissionsError',
                options: {
                  creation: draft,
                },
              },
            });
            this[types.CLOSE_EDITING_SESSION]();
            appStore[types.UPDATE_LOADING](false);
            return;
          }

          /*
            IMPORTANT: The order of the next three statements should be preserved because
            INIT_DRAFT will modify the session so the observer must already be attached.
            1. SET_SESSION with initial value
            2. Attach observeDeep() to session
            3. INIT_DRAFT
          */
          this.session = jsDoc.session;
          yDoc.get('session').observeDeep(() => {
            this.session = yDoc.get('session').toJSON();
          });
          this[types.INIT_DRAFT]({ draft, pageId });
          this[types.START_POLL]();
          this[types.INIT_DRAFT_UNDO]();

          yDoc.get('messages').get('addModuleConflictMessages').observeDeep(() => {
            this[types.SHOW_ADD_MODULE_CONFLICT_MESSAGES]();
          });

          yDoc.get('draft').observeDeep((changes, transaction) => {
            let changeNotHandled = false;
            changes.forEach((change) => {
              if (utils.isUpdateDraft(change)) {
                this[types.APPLY_UPDATE_DRAFT]({ change, yDoc });
              } else if (utils.isUpdateDraftOptions(change)) {
                this[types.APPLY_UPDATE_DRAFT_OPTIONS]({ change, yDoc });
              } else if (utils.isUpdateSection(change)) {
                this[types.APPLY_UPDATE_SECTION]({ change, yDoc, transaction });
              } else if (utils.isUpdateDraftMetadata(change)) {
                this[types.APPLY_UPDATE_DRAFT_METADATA]({ change, yDoc });
              } else if (utils.isUpdateModule(change)) {
                this[types.APPLY_UPDATE_MODULE]({ change, transaction });
              } else if (utils.isUpdateModalModule(change)) {
                this[types.APPLY_UPDATE_MODAL_MODULE]({ change, transaction });
              } else if (utils.isAddOrDeleteModule(change)) {
                if (utils.isAddModule(change)) {
                  this[types.APPLY_ADD_MODULE]({ change, yDoc, transaction });
                }
                if (utils.isDeleteModule(change)) {
                  this[types.APPLY_DELETE_MODULE]({ change, yDoc, transaction });
                }
              } else if (utils.isAddOrDeleteModalModule(change)) {
                if (utils.isAddModalModule(change)) {
                  this[types.APPLY_ADD_MODAL_MODULE]({ change, yDoc, transaction });
                }
                if (utils.isDeleteModalModule(change)) {
                  this[types.APPLY_DELETE_MODAL_MODULE]({ change, yDoc, transaction });
                }
              } else if (utils.isUpdatePage(change) || utils.isUpdatePageOptions(change)) {
                this[types.APPLY_UPDATE_PAGE]({ change, yDoc, transaction });
              } else if (utils.isAddPage(change)) {
                this[types.APPLY_ADD_PAGE]({ change, yDoc, transaction });
              } else if (utils.isDeletePage(change)) {
                this[types.APPLY_DELETE_PAGE]({ change, yDoc, transaction });
              } else if (utils.isUpdateAddModalCanvas(change)) {
                this[types.APPLY_ADD_MODAL_CANVAS]({ change, yDoc });
              } else {
                changeNotHandled = true;
              }

              // If we've already published at least once, then flag unshared changes.
              if (sharedDoc.isHost
                  && !utils.isUpdateDraftMetadata(change)
                  && !utils.isUpdateDraftShares(change)
                  && !utils.isUpdateDraftOptionsChatEnabled(change)
                  && !yDoc.get('session').get('draftIsDirty')
                  && yDoc.get('draft').get('metadata').get('published_on')) {
                this[types.SET_DRAFT_IS_DIRTY](true);
              }
            });

            // if the change wasn't handled, update commit mutation and api update
            // for the entire draft
            if (changeNotHandled) {
              const updatedDraft = yDoc.toJSON().draft;
              this.draft = updatedDraft;
              if (sharedDoc.isHost) {
                this[types.ENQUEUE_DRAFT_PATCH](updatedDraft);
              }
            }

            // make sure there are no duplicate indexes as a result of changes
            this[types.CHECK_SORT_INDEXES]();

            /*
              Whenever a page or module is updated, check the z_positions for its
              modules to ensure there are no duplcates. If there are duplicates, then the
              duplicate values will be reassigned unique values. This can happen if two users
              move a module to the same layer at the same time. This ensures each module
              is always on its own layer.
            */
            this[types.CHECK_MODULE_Z_POSITIONS](changes);
          });

          appStore[types.UPDATE_LOADING](false);
          this.isConnected = true;
        });

        sharedDoc.on('host-changed', () => {
          /*
            When the host changes and the current user is the new host,
            save the current state of the draft to the server. This is a safety
            net in case the host user's session is closed before outstanding changes
            can be saved. With this, the newly set host will ensure any changes not saved
            by the previous host are saved to the server.
          */
          if (sharedDoc.isHost) {
            const yDoc = sharedDoc.document;
            if (yDoc) {
              this[types.ENQUEUE_DRAFT_PATCH](yDoc.toJSON().draft);
            }
          }
        });

        sharedDoc.on('connected-users-changed', (connectedUsers) => {
          const users = connectedUsers.map((user) => ({
            id: user.id,
            clientId: user.clientId,
            firstName: get(user, 'data.userData.firstName'),
            lastName: get(user, 'data.userData.lastName'),
            isHost: user.isHost,
            isCurrentUser: user.isCurrentUser,
            connectionTime: user.connectionTime,
          }));
          this.connectedUsers = users;
          this[types.SET_CONNECTED_USERS_COLORS]();
        });

        const onDisconnected = (loadingState) => {
          this.isConnected = false;
          this.connectedUsers = null;
          appStore[types.UPDATE_LOADING](loadingState);
          this[types.CLEAR_DRAFT]();
          this[types.CLOSE_UNDO_TRACKING]();
        };

        sharedDoc.on('disconnected', () => onDisconnected(true));
        sharedDoc.on('closed', () => onDisconnected(false));

        sharedDoc.on('error', async ({ error }) => {
          const closeSession = async () => {
            await this[types.CLOSE_EDITING_SESSION]();
            appStore[types.UPDATE_LOADING](false);
          };

          const setDefaultError = () => {
            errorStore[types.SET_ERROR]({
              active: true,
              error: {
                component: 'EditingSessionEndedError',
                options: {
                  error,
                },
              },
            });
          };

          const setPermissionError = async () => {
            // The user doesn't have sufficient permissions for this draft. To
            // prevent an infinite spinner, show the error page and stop trying
            // to load the draft
            let accessLevel;
            try {
              await this[types.GET_DRAFT_REFERENCES](payload);
            } catch (err) {
              errorStore[types.SET_ERROR]({
                active: true,
                error: get(err, 'response.data', err),
              });
            } finally {
              accessLevel = this.draftAccessLevel;
            }

            if (accessLevel === 'view' || accessLevel === 'readonly') {
              errorStore[types.SET_ERROR]({
                active: true,
                error: {
                  component: 'ViewPermissionsError',
                  options: {
                    draftReferenceId: draftPointerAssetReference
                      && draftPointerAssetReference.reference_id,
                    draftType: get(draftOptions, 'type'),
                  },
                },
              });
            } else {
              setDefaultError();
            }
          };

          const isPermissionError = error.status === 403 && error.type === 'PermissionError';

          /*
            Close the editing session for unrecoverable errors and then
            either show a permission error or the default error with error info.
          */
          if (error instanceof SharedDocumentPollingError
            || error instanceof SharedDocumentAuthError
            || error instanceof SharedDocumentCreateSessionError
            || error instanceof SharedDocumentLoadSessionError
            || error.message.includes('Network Error')
            || error.message.match(/timeout/i)
            || isPermissionError) {
            closeSession();
            if (isPermissionError) {
              setPermissionError();
            } else {
              setDefaultError();
            }
          }
        });

        sharedDoc.open();
      } catch (error) {
        await this[types.CLOSE_EDITING_SESSION]();
        appStore[types.UPDATE_LOADING](false);
        if (error instanceof DraftIsDeletedError) {
          throw error;
        } else {
          errorStore[types.SET_ERROR]({
            active: true,
            error: get(error, 'response.data', error),
          });
        }
      }
    },
    [types.SET_SHARED_DOCUMENT](sd) {
      // This action is only used in tests, to be able to replace
      // the sharedDoc easily
      sharedDoc = sd;
    },
    [types.SET_CONNECTED_USERS_COLORS]() {
      const { connectedUsers, connectedUsersColors } = this;

      // The draft owner should always get a color and be displayed
      const draftOwner = {
        clientId: get(this, 'draft.owner_guid'),
      };

      if (connectedUsers) {
        [draftOwner, ...connectedUsers].forEach((user) => {
          if (!user.clientId || user.clientId in connectedUsersColors) return;
          const colorIdx = Object.keys(connectedUsersColors).length % 15;
          connectedUsersColors[user.clientId] = USER_COLORS[colorIdx];
        });
        this.connectedUsersColors = connectedUsersColors;
      }
    },
    async [types.CLOSE_EDITING_SESSION]() {
      const chatStore = useChatStore();

      this[types.KILL_POLL]();
      chatStore[types.CLOSE_EDITING_SESSION](null);
      this[types.CLOSE_UNDO_TRACKING]();

      if (sharedDoc) {
        // Wait for the SharedDocument to clear its message queue before continuing.
        await sharedDoc.destroy();
        sharedDoc = null;
        this.isConnected = false;
        this.connectedUsers = null;
        this[types.CLEAR_DRAFT]();
        this.session = null;
      }
    },
    [types.INIT_DRAFT_UNDO]() {
      undoManager = new UndoManager();

      // Update flags in store that tell UI whether undo/redo is possible.
      const updateUndoRedoFlags = () => {
        this.canUndo = undoManager.canUndo;
        this.canRedo = undoManager.canRedo;
      };

      undoManager.on('stack-changed', updateUndoRedoFlags);

      updateUndoRedoFlags();
    },
    [types.REMOVE_FROM_UNDO_HISTORY](filter) {
      if (undoManager) {
        undoManager.clearStacks(filter);
      }
    },
    [types.CLOSE_UNDO_TRACKING]() {
      undoManager = null;
      this.canUndo = false;
      this.canRedo = false;
    },
    [types.VIEW_UNDO_REDO_ACTION](stackItem, action) {
      /*
        Try to ensure that the undo or redo change will be visible in the UI.
        Attempts to navigate to the page and open the panel where the change
        is taking place so the user can see it.
      */
      // Waits for the associated panel to open before executing the undo/redo command.
      let waitForPanel = false;
      // Get the panel name where the undo/redo changes can be seen by the user.
      const panelName = stackItem.tags?.get('canvasName') || 'pageDrawer';
      if (panelName === 'pageDrawer') {
        // If a modal is open, then close it and wait.
        if (this.currentModalCanvasId) {
          this.currentModalCanvasId = null;
          waitForPanel = true;
        }
        // If the page drawer is not open, then open it and wait.
        if (!this.topNavDrawerIsOpen) {
          this.topNavDrawerIsOpen = true;
          waitForPanel = true;
        }
      } else {
        // If the page drawer is open, then close it and wait.
        if (this.topNavDrawerIsOpen) {
          this.topNavDrawerIsOpen = false;
          waitForPanel = true;
        }
        // Get the page or modal this undo item is targeting.
        const pageOrModalId = stackItem.tags?.get('pageOrModalId');
        if (pageOrModalId) {
          if (panelName === 'modules') {
            // If a modal is open and needs to be closed or the page needs to change, then wait.
            if (this.currentModalCanvasId || this.currentPageId !== pageOrModalId) {
              waitForPanel = true;
            }
            // Navigate to the page where the change is taking place.
            this[types.VIEW_PAGE_BY_ID](pageOrModalId);
          } if (panelName === 'notes') {
            const notesIsOpen = this.editorDrawers.notes;
            /*
              If a modal needs to be closed or the page needs to change
              or the notes panel needs to be opened, then wait.
            */
            if (
              this.currentModalCanvasId
              || this.currentPageId !== pageOrModalId
              || !notesIsOpen
            ) {
              waitForPanel = true;
            }
            // If the notes panel is not open, then open it.
            if (!notesIsOpen) {
              this.editorDrawers.addModule = false;
              this.editorDrawers.customization = false;
              this.editorDrawers.notes = true;
            }
            this[types.VIEW_PAGE_BY_ID](pageOrModalId);
          } else if (panelName === 'modalModules') {
            /*
              If the modal needs to be opened or a different modal id
              needs to be loaded, then wait.
            */
            if (
              !this.currentModalCanvasId
              || this.currentModalCanvasId !== pageOrModalId
            ) {
              waitForPanel = true;
            }
            // Display the modal where the change is taking place.
            this[types.VIEW_MODAL_CANVAS_BY_ID](pageOrModalId);
          }
        }
      }
      if (waitForPanel) {
        // Turn off panel animations
        this.editorDrawersAreAnimated = false;
        // Disable undo/redo while opening the related panel.
        this.canUndo = false;
        this.canRedo = false;
        /*
          Wait a moment for the panel to be displayed before executing the undo or redo.
          This is so the change in the UI is not hidden and can be seen by the user.
        */
        setTimeout(() => {
          this.editorDrawersAreAnimated = true;
          // Run the undo/redo command
          action();
        }, 250);
      } else {
        // Run the undo/redo command
        action();
      }
    },
    [types.UNDO_DRAFT_EDIT]() {
      if (undoManager && undoManager.canUndo && this.canUndo) {
        const undoItem = first(undoManager.undoStack);
        // Try to ensure the next undo operation is visible in the UI.
        this[types.VIEW_UNDO_REDO_ACTION](undoItem, () => {
          undoManager.undo();
        });
      }
    },
    [types.REDO_DRAFT_EDIT]() {
      if (undoManager && undoManager.canRedo && this.canRedo) {
        const redoItem = first(undoManager.redoStack);
        // Try to ensure the next redo operation is visible in the UI.
        this[types.VIEW_UNDO_REDO_ACTION](redoItem, () => {
          undoManager.redo();
        });
      }
    },
    async [types.ADD_MODAL_CANVAS](payload = {}) {
      const newModalCanvas = {
        ...utils.newModalCanvasTemplate(),
        ...payload,
      };

      // Make sure we don't carry over old module ids
      newModalCanvas.modules = newModalCanvas.modules.map((mod) => ({
        ...mod,
        id: uuid(),
      }));

      sharedDoc.change((yDoc) => {
        const yDraft = yDoc.get('draft');
        const yModals = yDraft.get('modals');

        if (yModals) {
          yModals.push([yjsHelper.toY(newModalCanvas)]);
        } else {
          yDraft.set('modals', yjsHelper.toY([newModalCanvas]));
        }
      }, LOCAL_TRANSACTION);
    },
    async [types.APPLY_ADD_MODAL_CANVAS]({ change, yDoc }) {
      // Get the modal
      chain([...change.changes.added.values()])
        .map((added) => added.content.type.get('id'))
        .uniq()
        .value()
        .forEach((newModalId) => {
          const modals = yDoc.get('draft').get('modals');
          const modal = yjsHelper.find(modals, (yModal) => yModal.get('id') === newModalId);
          if (modal) {
            const newModal = modal.toJSON();

            // Update state
            this.draft.modals.push(newModal);

            if (sharedDoc.isHost) {
              this[types.ENQUEUE_MODAL_PATCH]({ modal: newModal, action: 'add' });
            }
          }
        });
    },
    [types.SET_DURATION](duration) {
      this.videoDurations[duration.id] = duration;
    },
    async [types.CREATE_PREVIEW](previewData) {
      const assetStore = useAssetStore();
      const assessmentStore = useAssessmentStore();
      const errorStore = useErrorStore();
      try {
        if (!this.templates) await this[types.GET_TEMPLATES]();

        const draft = {
          id: uuid(),
          name: previewData.name || utils.defaultDraftName(previewData.options.type),
          options: previewData.options,
          modals: [],
          pages: previewData.pages.map((page, pageIndex) => {
            const pageTemplate = find(
              this.templates,
              (template) => template.name === page.template,
            );
            if (!pageTemplate) {
              throw new Error(i18next.t('Template %(template)s was not found.', { template: i18next.t(page.template) }));
            }
            const previewPage = merge(
              utils.newPageTemplate(previewData.options.type),
              {
                options: page.options,
                title: page.title || undefined,
                sort_index: pageIndex + 1,
              },
            );
            page.modules.forEach((mod) => {
              const templateMod = first(pageTemplate.pages).modules.find((tempMod) => {
                const modDisplayId = get(mod, 'options.display_id');
                const tempModDisplayId = get(tempMod, 'options.display_id');
                return tempModDisplayId && modDisplayId && tempModDisplayId === modDisplayId;
              });
              if (templateMod) {
                let layout = cloneDeep(templateMod.layout);
                if (previewData.options.type !== 'board') {
                  layout = layoutToPixels(templateMod.layout);
                }

                if (templateMod.type === 'placeholder') {
                  const defaultMod = moduleDefaultsList.find((func) => func().type === mod.type);
                  if (defaultMod) {
                    const gridDisabled = previewData.options.type !== 'board';
                    const previewMod = merge(
                      utils.newModuleTemplate(defaultMod(gridDisabled)),
                      {
                        layout,
                        options: mod.options,
                      },
                    );
                    previewMod.options = omit(previewMod.options, 'display_id');
                    previewPage.modules.push(previewMod);
                  }
                } else {
                  const previewMod = merge(
                    utils.newModuleTemplate(cloneDeep(templateMod)),
                    {
                      layout,
                      options: {
                        ...mod.options,
                      },
                    },
                  );
                  previewMod.options = omit(previewMod.options, 'display_id');

                  // Special handling for text modules.
                  if (templateMod.type === 'text') {
                    /*
                      Need to use the text placeholder textile string (from the text module
                      within the template) to format the content provided in the preview data.
                    */
                    previewMod.options.data_type = 'textile';
                    const useUnformattedContent = () => {
                      // No text formatting available in the template so use plain paragraph tag.
                      previewMod.options.content = `p. ${previewMod.options.content}`;
                    };
                    const placeholder = get(previewMod, 'options.text_placeholder');
                    if (placeholder) {
                      const placeholderTextSlotExp = /\$t{(.*)}/;
                      const placeholderTextSlot = placeholder
                        && placeholder.match(placeholderTextSlotExp);

                      if (placeholderTextSlot) {
                        previewMod.options.content = placeholder.replace(
                          placeholderTextSlotExp,
                          previewMod.options.content,
                        );
                      } else {
                        useUnformattedContent();
                      }
                    } else {
                      useUnformattedContent();
                    }
                  }

                  previewPage.modules.push(previewMod);
                }
              }
            });

            return previewPage;
          }),
        };

        this[types.CLOSE_EDITOR_DRAWERS]();
        this.draft = draft;
        this.accessLevelForDraft = null;
        assetStore[types.CACHE_ASSETS_AND_UPLOADS]();
        assessmentStore[types.CACHE_TEIS]();
        await this[types.VIEW_PAGE_BY_ID](first(draft.pages).id);
        this[types.SET_IS_PREVIEW_MODE](true);
        bus.emit('preview-loaded', omit(cloneDeep(draft), 'id'));
      } catch (error) {
        this[types.CLEAR_DRAFT]();
        errorStore[types.SET_ERROR]({
          active: true,
          error: get(error, 'response.data') || {
            meta: {
              message: error.message,
            },
          },
        });
        bus.emit('preview-error', error);
      }
    },
    async [types.SAVE_DRAFT](payload) {
      const appStore = useAppStore();
      const { draft, originAssetId } = payload;
      const postUrl = originAssetId ? `/drafts/?origin_asset_id=${originAssetId}` : '/drafts/';

      const result = await this.api.post(postUrl, draft,
        {
          headers: {
            'X-Token': appStore.apiToken,
          },
        });
      if (result.status !== 200) {
        throw result;
      }
      return result.data?.draft;
    },
    async [types.CREATE_CREATION](draftId) {
      const appStore = useAppStore();
      // Note: This draftId must be the mongo id, not the asset id.
      const result = await this.api.post('/creations/',
        { id: draftId },
        {
          headers: {
            'X-Token': appStore.apiToken,
          },
        });

      if (result.status !== 201) throw result;
      return result.data?.creation;
    },
  },
});

export default useEditorStore;
